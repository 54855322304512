import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import useFetchDataById from 'hooks/useFetchDataById'
import useQueryParams from 'hooks/useQueryParams'

import { getEntityFilters } from 'services/localStorage.service'
import { isChamberPressurizationOnly } from 'utils/diveRecordFunctions'
import { formatDate } from 'services/moment.service'

import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'
import FILTERS from 'constants/filters'
import { DIVE_RECORD_STATUS, DIVE_RECORD_TYPE } from 'constants/enums'

import Breadcrumbs from 'components/Breadcrumbs'
import TabNavigation from 'components/tabs/TabNavigation'
import GeneralInformationSummary from './diveRecord/components/generalInformation/GeneralInformationSummary'
import EquipmentSummary from './diveRecord/components/equipment/EquipmentSummary'
import DiveProfileSummary from './diveRecord/components/diveProfile/DiveProfileSummary'
import WorkPerformedSummary from './diveRecord/components/workPerformed/WorkPerformedSummary'
import DiveSummary from './diveRecord/components/diveSummary/DiveSummary'
import FeedbackSummary from './diveRecord/components/verification/FeedbackSummary'
import VerificationBadge from 'components/VerificationBadge'
import VerificationLevelIcon from 'components/VerificationLevelIcon'

const DiveRecordSummary = () => {
    const t = useTranslate()
    const { projectId, diveRecordId } = useParams()
    const { tab } = useQueryParams()
    const { pathname } = useLocation()

    const isMyProjects = pathname.includes(ROUTES.MY_DIVE_PROJECTS)
    const projectRoute = isMyProjects ? ROUTES.MY_DIVE_PROJECTS : ROUTES.PROJECT
    const diveRecordRoute = isMyProjects
        ? ROUTES.MY_PROJECT_RECORDS
        : ROUTES.DIVE_RECORDS

    const { data } = useFetchDataById(ENTITIES.DIVE_RECORD, diveRecordId, {
        include: [
            'project',
            'diveRecordGeneralInformation',
            'diveRecordGeneralInformation.bodyOfWater',
            'diveRecordGeneralInformation.bottomConditions',
            'diveRecordGeneralInformation.country',
            'diveRecordGeneralInformation.region',
            'diveRecordGeneralInformation.divingMode',
            'diveRecordGeneralInformation.divingPlatform',
            'diveRecordGeneralInformation.industry',
            'diveRecordGeneralInformation.locationType',
            'diveRecordGeneralInformation.waterQualities',
            'diveRecordGeneralInformation.supervisor',
            'diveRecordGeneralInformation.divePhysicalCharacteristics',
            'diveRecordGeneralInformation.projectIndustryType',
            'diveRecordGeneralInformation.divers',
            'diveRecordEquipment',
            'diveRecordEquipment.divingApparelProtection',
            'diveRecordEquipment.otherDivingApparelProtections',
            'diveRecordEquipment.breathingApparatusType',
            'diveRecordEquipment.buoyancyFlotations',
            'diveRecordEquipment.otherEquipmentOutfittings',
            'diveRecordEquipment.gasCylinders',
            'diveRecordEquipment.gasCylinders.gasCylinderPurpose',
            'diveRecordEquipment.gasCylinders.gasContents',
            'diveRecordDiveData',
            'diveRecordDiveData.diveEvents',
            'diveRecordDiveData.surfaceDecompressionEvents',
            'diveRecordDiveData.chamberPressurizationEvents',
            'diveRecordDiveData.chamberDecompressionEvents',
            'diveRecordDiveData.bellRunEvents',
            'diveRecordDiveData.lockOutEvents',
            'diveRecordWorkPerformed',
            'diveRecordWorkPerformed.tasks',
            'diveRecordWorkPerformed.supervisorTasks',
            'diveRecordWorkPerformed.objects',
            'diveRecordWorkPerformed.activities',
            'diveRecordWorkPerformed.tools',
            'diveRecordDiveSummary',
            'diveRecordDiveSummary.mentalWellnessElements',
            'diveRecordDiveSummary.traumaInjuryLevel',
            'diveRecordDiveSummary.decompressionIllness',
            'diveRecordDiveSummary.pressureInjuries',
            'diveRecordDiveSummary.equipmentFailures',
            'diveRecordDiveSummary.closeCall',
            'diveRecordDiveSummary.files',
            'diveRecordVerification',
            'diveRecordDiveData.saturationDailyEvents',
            'diveRecordGeneralInformation.breathingApparatusType',
            'diveRecordFirstLevelVerification',
            'diveRecordFirstLevelVerification.file',
        ],
    })

    const diveRecordName = `${
        data?.diveRecordGeneralInformation?.nameOfProject
    } ${formatDate(data?.diveRecordGeneralInformation?.dateOfDive)} #${
        data?.recordNumber
    }`

    if (!data) return null

    const TABS = {
        GENERAL_INFORMATION: 'general-information',
        EQUIPMENT: 'equipment',
        DIVE_PROFILE: 'dive-profile',
        WORK_PERFORMED: 'work-performed',
        DIVE_SUMMARY: 'dive-summary',
        FEEDBACK: 'feedback',
    }

    const TAB_COMPONENTS = {
        [TABS.GENERAL_INFORMATION]: (
            <GeneralInformationSummary
                data={data.diveRecordGeneralInformation}
                diveRecordId={data.id}
                unitImperial={data.unitImperial}
                diveRecordType={data.diveRecordType}
            />
        ),
        [TABS.EQUIPMENT]: (
            <EquipmentSummary
                data={data.diveRecordEquipment}
                diveRecordId={data.id}
                unitImperial={data.unitImperial}
            />
        ),
        [TABS.DIVE_PROFILE]: (
            <DiveProfileSummary
                data={data.diveRecordDiveData}
                diveRecordId={data.id}
                unitImperial={data.unitImperial}
                tabQueryParam="subTab"
                divingMode={data.diveRecordGeneralInformation?.divingMode}
            />
        ),
        [TABS.WORK_PERFORMED]: (
            <WorkPerformedSummary
                data={data.diveRecordWorkPerformed}
                diveRecordId={data.id}
                diveRecordType={data.diveRecordType}
            />
        ),
        [TABS.DIVE_SUMMARY]: (
            <DiveSummary
                data={data.diveRecordDiveSummary}
                diveRecordId={data.id}
                diveRecordType={data.diveRecordType}
                dataAccessObjectUser={data.dataAccessObjectUser}
                creatorId={data.creator?.id}
                verifiedLevel={data.verifiedLevel}
            />
        ),
        [TABS.FEEDBACK]: (
            <FeedbackSummary
                data={data.diveRecordVerification}
                firstLevelVerification={data.diveRecordFirstLevelVerification}
                diveRecordId={data.id}
                diveRecord={data}
            />
        ),
    }

    const renderTabContent = () =>
        TAB_COMPONENTS[tab] || TAB_COMPONENTS[TABS.GENERAL_INFORMATION]

    let diveRecordTabs = [
        {
            key: TABS.GENERAL_INFORMATION,
            title: 'general.generalInformation',
        },
        {
            key: TABS.EQUIPMENT,
            title: 'general.equipment',
        },
        {
            key: TABS.DIVE_PROFILE,
            title: 'general.diveProfileData',
        },
        {
            key: TABS.WORK_PERFORMED,
            title: 'general.workPerformed',
        },
        {
            key: TABS.DIVE_SUMMARY,
            title: 'general.divePerformance',
        },
    ]

    if (
        isChamberPressurizationOnly(
            data.diveRecordGeneralInformation?.divingMode
        )
    ) {
        diveRecordTabs = diveRecordTabs.filter(
            (item) =>
                item.key !== TABS.EQUIPMENT && item.key !== TABS.WORK_PERFORMED
        )
    }

    if (Number(data.diveRecordType) === DIVE_RECORD_TYPE.SUPERVISOR_RECORD.id) {
        diveRecordTabs = diveRecordTabs.filter(
            (item) => item.key !== TABS.EQUIPMENT
        )
    }

    const verifiedDiveRecordTabs = [
        ...diveRecordTabs,
        {
            key: TABS.FEEDBACK,
            title: 'general.feedbackAndRatings',
        },
    ]

    const tabData = data.verifiedLevel ? verifiedDiveRecordTabs : diveRecordTabs

    return (
        <div className="_wr">
            <div className="_w">
                <div className="m-boxes__breadcrumbs">
                    <Breadcrumbs
                        breadcrumbs={
                            projectId
                                ? [
                                      {
                                          label: 'general.homepage',
                                          to: ROUTES.HOME,
                                          icon: ICONS.HOMEPAGE_BREADCRUMB,
                                          queryParams: getEntityFilters(
                                              FILTERS.DIVE_PROJECTS
                                          ),
                                      },
                                      {
                                          label: 'general.diveProjects',
                                          to: ROUTES.HOME,
                                          queryParams: getEntityFilters(
                                              FILTERS.DIVE_PROJECTS
                                          ),
                                      },
                                      {
                                          label: data.project?.name,
                                          to: `${projectRoute}/${projectId}`,
                                          translate: false,
                                          showAlways: true,
                                      },
                                      {
                                          label: 'general.diveRecords',
                                          to: `${projectRoute}/${projectId}/${diveRecordRoute}`,
                                          showAlways: true,
                                          queryParams: getEntityFilters(
                                              FILTERS.DIVE_RECORDS
                                          ),
                                      },
                                      {
                                          label: diveRecordName,
                                          to: `${projectRoute}/${projectId}/${diveRecordRoute}/${diveRecordId}`,
                                          translate: false,
                                          showAlways: true,
                                      },
                                  ]
                                : [
                                      {
                                          label: 'general.myDiveRecords',
                                          to: `/${ROUTES.DIVE_RECORDS}`,
                                          icon: ICONS.HOMEPAGE_BREADCRUMB,
                                          queryParams: getEntityFilters(
                                              FILTERS.DIVE_RECORDS
                                          ),
                                      },
                                      {
                                          label: diveRecordName,
                                          to: `/${ROUTES.DIVE_RECORDS}/${diveRecordId}`,
                                          translate: false,
                                      },
                                  ]
                        }
                    />
                </div>

                <div className="m-boxes fullWidth">
                    <div className="m-boxes__main -fullWidth -fullHeight">
                        <div className="space-between">
                            <div className="aligned-center -gap10">
                                {data.status !==
                                    DIVE_RECORD_STATUS.DRAFT.id && (
                                    <VerificationLevelIcon
                                        levelOfVerification={data.verifiedLevel}
                                    />
                                )}
                                <h3>{diveRecordName}</h3>
                            </div>
                            {data.status === DIVE_RECORD_STATUS.VERIFIED.id && (
                                <VerificationBadge label="general.verified" />
                            )}
                            {data.status ===
                                DIVE_RECORD_STATUS.SUBMITTED.id && (
                                <span className="a-status -blue a-captionsTextRegular">
                                    {t('general.submitted')}
                                </span>
                            )}
                            {data.status ===
                                DIVE_RECORD_STATUS.RE_SUBMITTED.id && (
                                <span className="a-status -blue a-captionsTextRegular">
                                    {t('general.resubmitted')}
                                </span>
                            )}
                        </div>
                        <div className="-mt20">
                            <TabNavigation tabs={tabData} tabQueryParam="tab" />
                        </div>
                        {renderTabContent()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DiveRecordSummary
