import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { useState } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'

import useFetchData from 'hooks/useFetchData'

import ENTITIES from 'constants/entities'
import { INPUT_FILED_TYPE, PROJECT_WORKER_STATUS } from 'constants/enums'

import Loader from 'components/Loader'
import InputField from 'components/formFields/InputField'
import SelectField from 'components/formFields/SelectField'
import SelectMultiselectGroupField from 'components/formFields/SelectMultiselectGroupField'
import FocusError from 'components/FocusError'
import Modal from 'components/Modal'

const AvailablePositionsForm = ({
    initialData,
    handleSubmit,
    handleClose,
    title,
}) => {
    const t = useTranslate()
    const { id } = useParams()

    const [resetSkills] = useState(false)

    const requiredMessage = t('form.error.required')

    const { data: workersOnSinglePosition } = useFetchData(
        ENTITIES.PROJECT_WORKER_ELASTIC,
        {
            project: id,
            status: PROJECT_WORKER_STATUS.EMPLOYED,
            projectPosition: initialData?.id,
        },
        initialData?.id !== undefined
    )

    const availablePositionsInitialValues = {
        diverPosition: initialData?.diverPosition ?? null,
        numOfWorkers: initialData?.numOfWorkers ?? '',
        fromPayRate: initialData?.fromPayRate ?? '',
        toPayRate: initialData?.toPayRate ?? '',
        projectPositionSkill: initialData?.projectPositionSkill ?? [],
        description: initialData?.description ?? '',
    }

    const availablePositionsValidation = Yup.object().shape({
        diverPosition: Yup.object().required(requiredMessage),
        numOfWorkers: Yup.number()
            .test('numOfWorkers', (value, { parent, createError }) => {
                if (
                    workersOnSinglePosition.length > 0 &&
                    Number(value) < Number(workersOnSinglePosition.length)
                ) {
                    return createError({
                        message: t(
                            'form.error.numOfWorkersMustBeGreatherThanWorkers'
                        ),
                        path: 'numOfWorkers',
                    })
                }
                return true
            })
            .integer(t('form.error.invalidNumber'))
            .min(1, t('form.error.invalidNumber'))
            .required(requiredMessage),
        fromPayRate: Yup.number()
            .integer(t('form.error.onlyIntegerNumber'))
            .min(0, t('form.error.invalidNumber')),
        toPayRate: Yup.number()
            .integer(t('form.error.onlyIntegerNumber'))
            .min(0, t('form.error.invalidNumber'))
            .test('toPayRate', (value, { parent, createError }) => {
                if (Number(value) < Number(parent.fromPayRate)) {
                    return createError({
                        message: t('form.error.mustBeMoreThanFrom'),
                        path: 'toPayRate',
                    })
                }
                return true
            }),
        description: Yup.string().max(100, t('form.error.valueTooLong')),
    })

    return (
        <Formik
            initialValues={availablePositionsInitialValues}
            onSubmit={handleSubmit}
            validationSchema={availablePositionsValidation}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FocusError />
                    <Modal
                        closeOnClickOutside={false}
                        title={title}
                        buttons={{
                            prevBtn: {
                                handleClick: handleClose,
                            },
                            nextBtn: {
                                label: 'button.save',
                            },
                        }}
                        noTranslateTitle
                        smallModalWithDropdowns
                    >
                        <div className="_wr">
                            <div className="_w">
                                <div className="_12 _l6 -mt20">
                                    <SelectField
                                        name="diverPosition"
                                        label="form.label.positionType"
                                        placeholder="form.placeholder.positionType"
                                        entityType={ENTITIES.DIVER_POSITION}
                                        searchable
                                        required
                                    />
                                </div>
                                <div className="_12 _l6 -mt20">
                                    <InputField
                                        name="numOfWorkers"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        label="form.label.numberOfWorkersNeeded"
                                        placeholder="form.placeholder.numberOfWorkersNeeded"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <div className="_6 _l3 first-field">
                                    <InputField
                                        name="fromPayRate"
                                        label="form.label.payRate"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                    />
                                </div>
                                <div className="_6 _l3 fakeLabel second-field">
                                    <InputField
                                        name="toPayRate"
                                        label="general.fakeLabel"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                    />
                                </div>
                                <div className="_12 _l6">
                                    <InputField
                                        name="description"
                                        placeholder="form.placeholder.description"
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <div className="_12 a-oneLineSelectGroup">
                                    <SelectMultiselectGroupField
                                        name="projectPositionSkill"
                                        description="general.requiredSkillsPerPosition"
                                        fieldsName={['skillCategory', 'skill']}
                                        fieldsShowLabel={[false, false]}
                                        fieldsPlaceholder={[
                                            'form.placeholder.category',
                                            'form.placeholder.skill',
                                        ]}
                                        fieldsEntityType={[
                                            ENTITIES.SKILL_CATEGORY,
                                            ENTITIES.SKILL,
                                        ]}
                                        fieldsRequired={[false, false]}
                                        disableAddOnlyForBoth={true}
                                        buttonLabel="button.addSkill"
                                        secondFieldRelation="skillCategories.id"
                                        fieldsSearchable={[true, true]}
                                        reset={resetSkills}
                                        dropup
                                        disableOneField={[false, true]}
                                    />
                                </div>
                            </div>
                            {isSubmitting && <Loader />}
                        </div>
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

AvailablePositionsForm.propTypes = {
    initialData: PropTypes.object,
    title: PropTypes.string,
}

AvailablePositionsForm.defaultProps = {
    initialData: null,
}

export default AvailablePositionsForm
