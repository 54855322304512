import React from 'react'
import { useNavigate } from 'react-router-dom'

import useLogout from 'hooks/useLogout'

import COLORS from 'constants/colors'
import ROUTES from 'constants/routes'
import { BUTTON_SIZE, BUTTON_STATUS } from 'constants/enums'
import ICONS from 'constants/icons'

import { LogoComponent } from 'components/icons'
import Button from 'components/Button'

const CompanyNoAccess = () => {
    // delete this when SND-4080 is done

    const navigate = useNavigate()

    const { handleLogout } = useLogout()

    const handleGoBack = () => {
        navigate(ROUTES.LOGIN)
        handleLogout()
    }

    return (
        <div
            style={{
                position: 'relative',
                zIndex: 10,
                height: '100vh',
            }}
            className="fullWidth fullHeight aligned-center justify-center centered-text defaultBcg"
        >
            <div
                style={{
                    position: 'absolute',
                    top: '30px',
                    left: '0',
                }}
            >
                <div
                    style={{
                        paddingLeft: '40px',
                    }}
                >
                    <Button
                        btnClass={BUTTON_STATUS.TERTIARY}
                        buttonSize={BUTTON_SIZE.SMALL}
                        icon={ICONS.ARROW_LEFT}
                        iconColor={COLORS.LIGHT_BLUE}
                        onClick={handleGoBack}
                        label="button.logout"
                        additionalClasses="-whiteText"
                    />
                </div>
            </div>
            <div
                style={{
                    maxWidth: '800px',
                    padding: '20px',
                }}
            >
                <LogoComponent
                    width={250}
                    color1={COLORS.WHITE}
                    color2={COLORS.LIGHT_BLUE}
                />
                <h1 className="-mt20 a-whiteText">
                    Thank You for Your Interest in Skill N Depth!
                </h1>
                <p className="-mt20 a-lightText a-whiteText">
                    We’re thrilled to have you explore the possibilities our
                    platform offers. Our team will reach out to you within the
                    next few days to guide you through the onboarding process.
                    Once connected, you’ll receive full access to Skill N Depth
                    and all its features.
                </p>
                <h3 className="-mt30 a-whiteText">
                    We look forward to supporting your journey with us!
                </h3>
            </div>
        </div>
    )
}

export default CompanyNoAccess
