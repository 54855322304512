export const formatPayRate = (item) => {
    return item || null
}

export const formatFromToValues = (from, to) => {
    const fromValue = from ? formatPayRate(from) : '0-'
    const toValue = to ? formatPayRate(to) : ''
    let formattedValue = null
    if (from && to) {
        formattedValue = '$' + fromValue + '-' + '$' + toValue
    } else if (from && !to) {
        formattedValue = 'from ' + fromValue + '$'
    } else {
        formattedValue = '$' + fromValue + '$' + toValue
    }

    return formattedValue
}
