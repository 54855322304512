import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import { formatFromToValues } from '../common/projectFormatActions'

import { ALERT_TYPES } from 'constants/enums'

import AvailablePositionsForm from './AvailablePositionsForm'

const CreateAvailablePositions = ({
    availablePositions,
    setAvailablePositions,
    setCreate,
}) => {
    const { setAlert } = useContext(AlertContext)
    const t = useTranslate()

    const handleClose = () => {
        setCreate(false)
    }

    const handleSubmit = (values, { setSubmitting }) => {
        try {
            setSubmitting(true)
            setAvailablePositions([
                ...availablePositions,
                {
                    ...values,
                    payRatePerDay:
                        !values.fromPayRate && !values.toPayRate
                            ? ''
                            : formatFromToValues(
                                  values.fromPayRate,
                                  values.toPayRate
                              ),
                },
            ])
            setCreate(false)
            setAlert(t('message.successPositionCreate'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(t('message.errorPositionEdit'), ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <div scroll-attribute="availablePositionsErrorScroll">
            <AvailablePositionsForm
                availablePositions={availablePositions}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                title={t('general.addProjectPosition')}
            />
        </div>
    )
}

export default CreateAvailablePositions
