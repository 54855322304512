import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import ICONS from 'constants/icons'
import {
    BUTTON_STATUS,
    BUTTON_TYPE,
    FILE_UPLOAD_TYPE,
    INPUT_FILED_TYPE,
} from 'constants/enums'

import InputField from 'components/formFields/InputField'
import DateTimeField from 'components/formFields/DateTimeField'
import Button from 'components/Button'
import FileUploadField from 'components/formFields/FileUploadField'
import { TextAreaField } from 'components/formFields'
import COLORS from 'constants/colors'
import Separator from 'components/Separator'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'
import {
    MOMENT_FORMATS,
    getIsDateSameOrAfter,
    getIsDateSameOrBeforeCurrentDate,
    getTodaysDate,
} from 'services/moment.service'

const RecordForm = ({ setRecords, setShowRecordForm, records }) => {
    const t = useTranslate()

    const initialValues = {
        nameOfShip: '',
        shipsPort: '',
        shipsOfficialNumber: '',
        shipsGrossTonnage: '',
        dateOfEngagement: '',
        placeOfEngagement: '',
        dateOfDischarge: '',
        placeOfDischarge: '',
        rank: '',
        position: '',
        descriptionOfVoyage: '',
        nameOfMaster: '',
        files: [],
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        nameOfShip: Yup.string().trim().required(requiredMessage),
        shipsPort: Yup.string().trim().required(requiredMessage),
        shipsOfficialNumber: Yup.string().trim().required(requiredMessage),
        shipsGrossTonnage: Yup.number()
            .min(0.1, t('form.error.shipsGrossTonnage'))
            .required(requiredMessage),
        dateOfEngagement: Yup.date()
            .test('dateOfEngagement', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)
                ) {
                    return true
                }
                return createError({
                    message: t('form.error.dateOfEngagementInTheFuture'),
                    path: 'dateOfEngagement',
                })
            })
            .required(requiredMessage),
        placeOfEngagement: Yup.string().trim().required(requiredMessage),
        dateOfDischarge: Yup.date()
            .test('dateOfDischarge', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrAfter(
                        value,
                        parent.dateOfEngagement,
                        MOMENT_FORMATS.DATE
                    ) ||
                    parent.dateOfEngagement === undefined
                ) {
                    return true
                }
                return createError({
                    message: t('form.error.dateOfDischargeBeforeEngagement'),
                    path: 'dateOfDischarge',
                })
            })
            .required(requiredMessage),
        placeOfDischarge: Yup.string().trim().required(requiredMessage),
        rank: Yup.string().required(requiredMessage),
        position: Yup.string().trim().required(requiredMessage),
        // descriptionOfVoyage: Yup.string().trim().required(requiredMessage),
        nameOfMaster: Yup.string().trim().required(requiredMessage),
        // recordInformationFiles: Yup.array()
        //     .min(1, t('form.error.atLeastOneCopyOfVisa'))
        //     .required(requiredMessage),
    })

    const onSubmit = ({ shipsGrossTonnage, ...formData }) => {
        setRecords((records) => [
            ...records,
            {
                ...formData,
                shipsGrossTonnage: Number(shipsGrossTonnage),
            },
        ])
        setShowRecordForm(false)
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({
                isSubmitting,
                values: { dateOfEngagement, dateOfDischarge },
            }) => (
                <Form>
                    <FocusError />
                    <div className="_wr">
                        <div className="_w">
                            <Separator />
                        </div>
                    </div>
                    <div className="_wr">
                        {records.length === 0 && (
                            <div className="_w">
                                <h3 className="-mb20 _12 a-bodyTextRegular">
                                    {t('general.recordInformation')}
                                </h3>
                            </div>
                        )}
                        <div className="_w">
                            <div className="_12 _m6">
                                <InputField name="nameOfShip" required />
                            </div>
                            <div className="_12 _m6">
                                <InputField name="shipsPort" required />
                            </div>
                            <div className="_12 _m6">
                                <InputField
                                    name="shipsOfficialNumber"
                                    required
                                />
                            </div>
                            <div className="_12 _m6">
                                <InputField
                                    name="shipsGrossTonnage"
                                    type={INPUT_FILED_TYPE.NUMBER}
                                    required
                                />
                            </div>
                            <div className="_12 _m6">
                                <DateTimeField
                                    name="dateOfEngagement"
                                    maxDate={getTodaysDate()}
                                    required
                                />
                            </div>
                            <div className="_12 _m6">
                                <InputField name="placeOfEngagement" required />
                            </div>
                            <div className="_12 _m6">
                                <DateTimeField
                                    name="dateOfDischarge"
                                    minDate={
                                        dateOfEngagement
                                            ? new Date(dateOfEngagement)
                                            : null
                                    }
                                    required
                                />
                            </div>
                            <div className="_12 _m6">
                                <InputField name="placeOfDischarge" required />
                            </div>
                            <div className="_12 _m6">
                                <InputField name="rank" required />
                            </div>
                            <div className="_12 _m6">
                                <InputField name="position" required />
                            </div>
                            <div className="_12">
                                <TextAreaField
                                    name="descriptionOfVoyage"
                                    // required
                                />
                            </div>
                            <div className="_12 _m6">
                                <InputField name="nameOfMaster" required />
                            </div>
                        </div>
                        <div className="_w -mt5">
                            <FileUploadField
                                name="files"
                                label="form.label.recordInformationFiles"
                                type={FILE_UPLOAD_TYPE.FILE}
                                // required
                            />
                        </div>
                        <div className="_w ">
                            <div className="_8 _m5 _l3 -mt20 fullWidthButton -left">
                                <Button
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    type={BUTTON_TYPE.SUBMIT}
                                    icon={ICONS.PLUS}
                                    iconColor={COLORS.LIGHT_BLUE}
                                    label="button.saveThisRecord"
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="_4 _m3 _l2 -mt20 fullWidthButton -right">
                                <Button
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    type={BUTTON_TYPE.BUTTON}
                                    label="button.cancel"
                                    onClick={() => setShowRecordForm(false)}
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>
                    </div>
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    )
}

RecordForm.propTypes = {
    setRecords: PropTypes.func.isRequired,
    setShowRecordForm: PropTypes.func.isRequired,
}

export default RecordForm
