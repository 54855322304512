import React, { Fragment, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import axios, { GET } from 'utils/axiosClient'
import QRCode from 'react-qr-code'
import { useTranslate } from 'react-polyglot'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
    ALERT_TYPES,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import useFetchDataById from 'hooks/useFetchDataById'

import Button from 'components/Button'
import Modal from 'components/Modal'
import { AlertContext } from 'contexts/AlertContext'

const IdentityVerification = ({
    profilePage,
    setCloseVerificationBanner,
    settingsPage,
}) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)
    const { currentUser } = useContext(CurrentUserContext)

    const [verificationUrl, setVerificationUrl] = useState('')

    const { data: userProfile } = useFetchDataById(
        ENTITIES.DIVER_PROFILE,
        currentUser.id,
        {}
    )

    if (!currentUser || currentUser.identityVerified === true) return null

    if (
        !userProfile ||
        userProfile?.completionProgress?.totalPercentage !== 100
    )
        return null

    const openVerificationIdentityModal = () => {
        axios(GET, `/identity-verification/get-link`, {})
            .then(async ({ data }) => {
                if (data && data.url) {
                    setVerificationUrl(data.url)
                }
            })
            .catch((error) => {
                setAlert(error, ALERT_TYPES.ERROR, t)
            })
    }

    const initIdentityVerification = () => {
        window.location = verificationUrl
    }

    const handleClose = async () => {
        setCloseVerificationBanner(true)
    }

    return (
        <div
            className={`${settingsPage ? '' : 'm-boxes__main -mb20 fullWidth'}`}
        >
            {!profilePage && !settingsPage && (
                <span
                    className="removeItemIcon -large"
                    onClick={handleClose}
                ></span>
            )}
            <div>
                {!settingsPage && (
                    <Fragment>
                        <span className="a-bodyTextMedium">
                            {t('identityVerification.title')}
                        </span>
                        <p className="-mt10 -mb20 a-mediumText">
                            <span className="a-mediumText a-lightText -opacity-60">
                                {t('identityVerification.boxTextFirstPart')}
                            </span>
                            <span className="a-mediumTextSemiBold">
                                {t('identityVerification.boxTextBold')}
                            </span>
                            <span className="a-mediumText a-lightText -opacity-60">
                                {t('identityVerification.boxTextSecondPart')}
                            </span>
                        </p>
                    </Fragment>
                )}
                <Button
                    type={BUTTON_TYPE.BUTTON}
                    btnClass={
                        settingsPage
                            ? BUTTON_STATUS.TERTIARY
                            : BUTTON_STATUS.PRIMARY
                    }
                    icon={settingsPage ? ICONS.ARROW_RIGHT : ''}
                    iconColor={settingsPage ? COLORS.SECONDARY : ''}
                    iconPosition={settingsPage ? ICON_POSITION.RIGHT : ''}
                    label={`${
                        settingsPage
                            ? 'button.startVerificationProcess'
                            : 'identityVerification.startButton'
                    }`}
                    onClick={openVerificationIdentityModal}
                    buttonSize={
                        settingsPage ? BUTTON_SIZE.XSMALL : BUTTON_SIZE.SMALL
                    }
                />

                <Modal
                    open={verificationUrl !== ''}
                    setOpen={() => {
                        setVerificationUrl('')
                    }}
                    title="identityVerification.modalTitle"
                    smallModal
                    buttons={{
                        prevBtn: {
                            handleClick: () => {
                                setVerificationUrl('')
                            },
                        },
                        nextBtn: {
                            label: 'identityVerification.goToPersona',
                            handleClick: initIdentityVerification,
                        },
                    }}
                >
                    <div className="_w">
                        <div className="_12">
                            <div className="-mt10 a-bodyTextRegular -mb20">
                                <div className="-mb20">
                                    {t('identityVerification.modalDescription')}
                                </div>

                                <div>
                                    {t('identityVerification.modalOptions')}
                                </div>
                            </div>
                        </div>
                        <div className="_12">
                            <div className="qrCodeBox -mt10">
                                <QRCode
                                    size={180}
                                    value={verificationUrl}
                                    viewBox={`0 0 210 210`}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

IdentityVerification.propTypes = {
    profilePage: PropTypes.bool,
    settingsPage: PropTypes.bool,
}

IdentityVerification.defaultProps = {
    profilePage: false,
    settingsPage: false,
}

export default IdentityVerification
