import _ from 'lodash'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import useFetchData from 'hooks/useFetchData'
import useWindowDimensions from 'hooks/useWindowDimension'
import useCanLeavePage from 'hooks/useCanLeavePage'
import { BASE_URL } from '../utils/axiosClient'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { SEARCH_DELAY } from 'constants/constants'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'
import ROUTES from '../constants/routes'

import Dropdown from 'components/Dropdown'
import Input from 'components/Input'
import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'
import Icon from './Icon'
import NoData from './NoData'

const MainSearch = ({ open, setOpen, showLabel, disabled, placeholder }) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { isTablet } = useWindowDimensions()

    const searchRef = useRef()
    const dropdownRef = useRef()

    const { isVerified } = useContext(CurrentUserContext)

    const { canLeavePage, handleShowModal } = useCanLeavePage()

    const { data, meta, isLoading, fetchData, loadMore } = useFetchData(
        ENTITIES.MAIN_SEARCH_ITEMS,
        {},
        isVerified &&
            (searchRef.current?.value !== undefined ||
                searchRef.current?.value !== '')
    )

    useEffect(() => {
        if (searchRef.current?.value) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [searchRef.current?.value])

    const handleChangeDebounce = useCallback(
        _.debounce((event) => {
            const { value } = event.target
            if (dropdownRef.current) {
                dropdownRef.current.scrollTop = 0
            }
            fetchData({ fullName: value })
        }, SEARCH_DELAY),
        []
    )

    const handleOpenModal = (entityType, entityId, profileHash) => {
        if (!canLeavePage) {
            if (entityType === 'company') {
                handleShowModal(
                    `${ROUTES.DIVING_CONTRACTOR_PROFILE}/${profileHash}`
                )
            } else {
                handleShowModal(`${ROUTES.DIVER_PROFILE}/${profileHash}`)
            }
        } else {
            if (entityType === 'company') {
                navigate(`${ROUTES.DIVING_CONTRACTOR_PROFILE}/${profileHash}`)
            } else {
                navigate(`${ROUTES.DIVER_PROFILE}/${profileHash}`)
            }
        }
        handleClear()
        setOpen(false)
    }

    // NOTE:: When the result is clicked, the search value should be deleted
    const handleClear = () => {
        //NOTE:: Open search result
        searchRef.current.value = ''
        fetchData({ fullName: '' })
    }

    const handleLoadMore = () => {
        if (!isLoading && loadMore) {
            fetchData({
                page: meta.currentPage + 1,
                fullName: searchRef.current.value,
            })
        }
    }

    const handleFocus = () => {
        if (searchRef.current.value) {
            setOpen(true)
        }
    }

    useEffect(() => {
        if (open && isTablet) {
            document.body.style.overflow = 'hidden'
            return () => {
                document.body.style.overflow = 'unset'
            }
        }
    }, [open])

    const renderSearchResults = () => {
        return (
            <ul>
                {data.map(
                    ({
                        id,
                        fullName,
                        imagePath,
                        entityType,
                        entityId,
                        identityVerified,
                        profileHash,
                    }) => (
                        <li
                            key={id}
                            className="-result a-mediumText"
                            onClick={() =>
                                handleOpenModal(
                                    entityType,
                                    entityId,
                                    profileHash
                                )
                            }
                        >
                            <div className="-resultImg">
                                {imagePath ? (
                                    <img
                                        alt=""
                                        src={`${BASE_URL}${imagePath}`}
                                    />
                                ) : (
                                    <span className="m-avatar__icon">
                                        <Icon
                                            name={ICONS.USER}
                                            color={COLORS.WHITE}
                                            size={ICON_SIZE.SIZE20}
                                        />
                                    </span>
                                )}
                            </div>
                            <div className="column -resultText">
                                <div className="aligned-center -nameWrapper">
                                    <span className="a-captionsTextRegular -name">
                                        {fullName}
                                    </span>
                                    {identityVerified && (
                                        <span className="verifiedIcon">
                                            <Icon
                                                name={
                                                    ICONS.VERIFICATION_PROFILE
                                                }
                                            />
                                        </span>
                                    )}
                                </div>
                                <span className="a-capitalize a-captionsTextRegular a-lightText -opacity-60">
                                    {entityType}
                                </span>
                            </div>
                        </li>
                    )
                )}
            </ul>
        )
    }

    return (
        <div className="m-searchField">
            <Input
                inputRef={searchRef}
                name="searchField"
                placeholder={placeholder}
                onChange={handleChangeDebounce}
                onFocus={handleFocus}
                icon={ICONS.SEARCH}
                iconColor={COLORS.DARK_BLUE_40}
                size={ICON_SIZE.SIZE16}
                showLabel={showLabel}
                disabled={disabled}
                mainSearch
            />
            <Dropdown
                open={open && searchRef.current?.value}
                dropdownRef={dropdownRef}
                handleReachedBottom={handleLoadMore}
                mainSearchDropdown
            >
                {data.length === 0 && (
                    <NoData
                        icon={ICONS.SEARCH_ICON}
                        title={t('general.noResults')}
                        description={t('general.noSearchResultsDesc')}
                        isSmallWindow
                    />
                )}
                {data.length > 0 && renderSearchResults()}

                {loadMore && (
                    <span
                        className="m-selectGroup__loader a-captionsTextRegular a-lightText"
                        onClick={handleLoadMore}
                    >
                        {t('general.loadMore')}
                    </span>
                )}
            </Dropdown>
        </div>
    )
}

MainSearch.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    showLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
}

MainSearch.defaultProps = {
    placeholder: 'form.placeholder.search',
}

export default clickOutsideHOC(MainSearch)
