import React, { useContext } from 'react'

import useFetchDataById from 'hooks/useFetchDataById'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import ENTITIES from 'constants/entities'
import { PROFILE_STEPS } from 'constants/constants'

import CompleteProfile from '../../../../components/CompleteProfile'

const CompleteDiverProfile = () => {
    const { userId, currentUser } = useContext(CurrentUserContext)

    const { data } = useFetchDataById(ENTITIES.DIVER_PROFILE, userId)

    if (!data) return null

    const stepsToComplete = PROFILE_STEPS.filter(
        ({ key }) => data.completionProgress[key] === 0
    )

    const completedSteps = PROFILE_STEPS.filter(
        ({ key }) => data.completionProgress[key] !== 0
    )

    return (
        <CompleteProfile
            name={currentUser.firstName}
            description="general.diverWelcomeDescription"
            showProgress={true}
            stepsToComplete={stepsToComplete}
            completedSteps={completedSteps}
            allSteps={PROFILE_STEPS}
            entityId={userId}
            currentUser={currentUser}
        />
    )
}

export default CompleteDiverProfile
