import { useContext } from 'react'

import ROUTES from 'constants/routes'
import { ALL_ROLES, ROLES } from 'constants/enums'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import ICONS from 'constants/icons'
import isAllowedAdci from 'utils/adciSecurityUtil'

const { ROLE_SUPER_ADMIN, ROLE_DIVER } = ROLES

export const ADMIN_MENU_ITEMS = [
    {
        key: 'skills',
        label: 'menuItems.skills',
        to: ROUTES.SKILLS_MANAGEMENT,
        roles: [ROLE_SUPER_ADMIN],
    },
]

export const USER_MENU_ITEMS = [
    {
        key: 'home',
        label: 'menuItems.homePage',
        to: ROUTES.HOME,
        icon: ICONS.HOMEPAGE,
        roles: ALL_ROLES,
        showInHeader: true,
    },
    {
        key: 'dive-projects',
        label: 'menuItems.diveProjects',
        to: ROUTES.MY_DIVE_PROJECTS,
        icon: 'dive_projects_outline',
        roles: ALL_ROLES,
        showInHeader: true,
    },
    {
        key: 'dive-records',
        label: 'menuItems.myRecords',
        to: ROUTES.DIVE_RECORDS,
        icon: 'dive_projects_outline',
        roles: [ROLE_DIVER],
        showInHeader: true,
    },
    {
        key: 'separator1',
        roles: ALL_ROLES,
        separator: true,
    },
    // NOTE:: for now settings are available only for the diver
    {
        key: 'settings',
        label: 'menuItems.settings',
        to: ROUTES.SETTINGS,
        icon: ICONS.SETTINGS,
        roles: [ROLE_DIVER],
    },
    {
        key: 'helpAndInfo',
        label: 'menuItems.helpAndInfo',
        to: ROUTES.HELP_AND_INFO,
        icon: ICONS.HELP_AND_INFO,
        roles: ALL_ROLES,
    },
    {
        key: 'checkCertificates',
        label: 'menuItems.checkCertificates',
        to: ROUTES.CHECK_CERTIFICATES,
        icon: ICONS.VERIFICATION_PROFILE,
        roles: [ROLES.ROLE_DIVING_CONTRACTOR],
        showInHeader: true,
        customCondition: isAllowedAdci
    },
    // {
    //     key: 'calendar',
    //     label: 'menuItems.calendar',
    //     to: ROUTES.CALENDAR,
    //     icon: 'calendar',
    //     roles: ALL_ROLES,
    //     showInHeader: true,
    //     notAvailableAtTheMoment: true,
    // },
    // {
    //     key: 'notification',
    //     label: 'menuItems.notification',
    //     to: ROUTES.NOTIFICATION,
    //     icon: 'notifications_no_circle',
    //     roles: ALL_ROLES,
    // },
    // {
    //     key: 'faq',
    //     label: 'menuItems.faq',
    //     to: ROUTES.FAQ,
    //     icon: 'faq',
    //     roles: ALL_ROLES,
    // },
    // {
    //     key: 'separator1',
    //     roles: ALL_ROLES,
    //     separator: true,
    // },
    // {
    //     key: 'legal',
    //     label: 'menuItems.legal',
    //     to: ROUTES.LEGAL,
    //     icon: 'legal',
    //     roles: ALL_ROLES,
    // },
]

const useMenuItems = (menuItems) => {
    const { currentUser } = useContext(CurrentUserContext)

    return menuItems.filter(({ roles = ALL_ROLES, customCondition = (user) => true }) =>
        roles.some((role) => role.name === currentUser.role.name) &&
        customCondition(currentUser)
    )
}

export default useMenuItems
