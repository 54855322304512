import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Document, Page } from 'react-pdf'
import { Form, Formik } from 'formik'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { getUserToken } from 'services/localStorage.service'

import useFetchDataById from 'hooks/useFetchDataById'
import usePremiumFeature from 'hooks/usePremiumFeature'
import useWindowDimensions from 'hooks/useWindowDimension'

import { BASE_URL } from 'utils/axiosClient'

import {
    ACCESS_CONTROL_MODULES,
    ACCESS_CONTROL_STATUSES,
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
} from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import { FEATURES } from 'constants/features'

import Button from 'components/Button'
import Loader from 'components/Loader'
import ExportProfileItem from './ExportProfileItem'
import EditTitleModal from './EditTitleModal'
import FileModal from 'components/FileModal'
import RequestAccess from '../../RequestAccess'
import PremiumButton from 'components/PremiumButton'
import Modal from 'components/Modal'

const ExportProfileAsCv = ({ user, myProfile, userID, refetchData }) => {
    const t = useTranslate()
    const formRef = useRef()

    const token = getUserToken()

    const { isSmallerTablet } = useWindowDimensions()
    const { hasFeatureAccess } = usePremiumFeature()
    const hasCvExportAccess = hasFeatureAccess(FEATURES.EXPORT_PROFILE_TO_CV)

    const { isDiver } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const [showTitleModal, setShowTitleModal] = useState(false)
    const [cvPdf, setCvPdf] = useState(null)
    const [showCvExample, setShowCvExample] = useState(false)

    const { id, firstName, lastName, currentTitle } = user

    const { data, isLoading } = useFetchDataById(
        ENTITIES.DATA_ACCESS_CHECK,
        userID,
        {
            accessModule: ACCESS_CONTROL_MODULES.USER_CV,
        },
        !myProfile,
        false,
        false
    )

    const status = data?.data.dataAccessStatus
    const hasAccess =
        myProfile ||
        [ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id].includes(status)

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldValue('currentTitle', currentTitle)
        }
    }, [currentTitle])

    const initialValues = {
        showFullName: true,
        showPhoto: true,
        showEmail: isDiver ? true : false,
        showPhoneNumber: isDiver ? true : false,
        showDateOfBirth: true,
        showLocation: true,
        showLanguages: true,
        showTotalExperience: true,
        showSkills: true,
        showExperiencePerDivingMode: true,
        showTitle: true,
        currentTitle: currentTitle || '',
        showSummary: true,
        showPastEmployment: true,
        showEducation: true,
        showVocationTraining: true,
        showSelectSkills: true,
        showAboutMe: true,
    }

    const handleSubmit = async (formData, { setSubmitting }) => {
        try {
            setSubmitting(true)
            const fileName =
                formData.showFullName === true
                    ? `${firstName} ${lastName} CV.pdf`
                    : `${firstName.substring(0, 1)}${lastName.substring(
                          0,
                          1
                      )} CV.pdf`

            setCvPdf({
                id: null,
                name: fileName,
                originalName: fileName,
                fileType: 'application/pdf',
            })
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    const fetchCvPdf = async () => {
        const response = await fetch(
            `${BASE_URL}/api/${ENTITIES.CV_CREATE}/${id}`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(formRef.current.values),
            }
        )

        return response
    }

    const viewCvExample = () => {
        setShowCvExample(true)
    }

    if (!hasAccess) {
        return (
            <RequestAccess
                module={ACCESS_CONTROL_MODULES.USER_CV}
                title="general.exportProfileAsCV"
                status={status}
                canRequestAccess={
                    status === ACCESS_CONTROL_STATUSES.NOT_FOUND.id
                }
                profile={user}
                checkAccessControl={refetchData}
            />
        )
    }

    return (
        <div>
            <h6 className="a-bodyTextMedium -mb20">
                {t('general.exportProfileAsCv')}
            </h6>
            <span className="a-mediumText a-lightText">
                {t('general.exportProfileAsCvDescription')}
            </span>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                innerRef={formRef}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <ExportProfileItem
                            title="general.personalInformation"
                            name="showFullName"
                        />
                        <ExportProfileItem name="showPhoto" />
                        <ExportProfileItem name="showEmail" />
                        <ExportProfileItem name="showPhoneNumber" />
                        <ExportProfileItem name="showDateOfBirth" />
                        <ExportProfileItem name="showLocation" />
                        <ExportProfileItem name="showLanguages" />
                        <ExportProfileItem name="showTotalExperience" />
                        <ExportProfileItem
                            title="general.skills"
                            name="showSkills"
                        />
                        <ExportProfileItem
                            title="general.divingExperience"
                            name="showExperiencePerDivingMode"
                        />
                        <ExportProfileItem
                            title="general.professionalSummary"
                            name="showTitle"
                            showBorder={false}
                        />
                        <div className="space-between -pb10 -mb10 a-separator -bottom">
                            <label className="a-mediumText">
                                {t('form.label.editCurrentTitleCV')}
                            </label>
                            <Button
                                label="button.editTitle"
                                onClick={() => setShowTitleModal(true)}
                                icon={ICONS.ARROW_RIGHT}
                                iconPosition={ICON_POSITION.RIGHT}
                                iconColor={COLORS.LIGHT_BLUE}
                                btnClass={BUTTON_STATUS.TERTIARY}
                                buttonSize={BUTTON_SIZE.XSMALL}
                            />
                        </div>
                        <ExportProfileItem name="showSummary" />
                        <ExportProfileItem
                            title="general.pastEmployment"
                            name="showPastEmployment"
                        />
                        <ExportProfileItem
                            title="general.education"
                            name="showEducation"
                        />
                        <ExportProfileItem
                            title="general.vocationalTraining"
                            name="showVocationTraining"
                        />
                        <ExportProfileItem
                            title="general.selectSkillsTraining"
                            name="showSelectSkills"
                        />
                        <div
                            className={
                                !hasCvExportAccess && isSmallerTablet
                                    ? '-pb90'
                                    : ''
                            }
                        >
                            <ExportProfileItem
                                title="general.aboutMe"
                                name="showAboutMe"
                                showBorder={false}
                            />
                        </div>
                        <div className="justify-end -mt40 m-step__buttons -gap20">
                            {!hasCvExportAccess && (
                                <Button
                                    label="button.viewCvExample"
                                    onClick={viewCvExample}
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                />
                            )}
                            <PremiumButton
                                label="button.exportAsPdf"
                                type={BUTTON_TYPE.SUBMIT}
                                disabled={isSubmitting}
                                feature={FEATURES.EXPORT_PROFILE_TO_CV}
                            />
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
            {showTitleModal && (
                <EditTitleModal setOpen={setShowTitleModal} formRef={formRef} />
            )}
            {cvPdf && (
                <FileModal
                    file={cvPdf}
                    uploaded={[cvPdf]}
                    setOpenFile={setCvPdf}
                    customFetch={fetchCvPdf}
                />
            )}
            {showCvExample && (
                <Modal
                    open={showCvExample}
                    setOpen={setShowCvExample}
                    closeOnClickOutside={false}
                    customClass="-pdfModal"
                    title="John_Doe_CV.pdf"
                    noTranslateTitle={true}
                >
                    <div className="pdf-content fullWidth">
                        <div className="-mb20 _12">
                            <Document file="/cv.pdf">
                                <Page pageNumber={1} />
                            </Document>
                        </div>
                    </div>
                </Modal>
            )}
            {isLoading && <Loader />}
        </div>
    )
}

export default ExportProfileAsCv
