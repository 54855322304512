import PropTypes from 'prop-types'
import { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { deleteEntityService } from 'services/entity.service'

import {
    NO_SKILLS_EXPERIENCE_TEXT,
    renderNoDataText,
} from '../../utils/noDataHelper'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import SkillRecordManage from './SkillRecordManage'
import CardHeader from 'components/card/CardHeader'

const SkillRecordList = ({
    data,
    fetchData,
    isLoading,
    profilePage,
    showActions,
    noDataText,
    refetchData,
}) => {
    const t = useTranslate()
    const [edit, setEdit] = useState(null)

    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const showNoDataText = noDataText === NO_SKILLS_EXPERIENCE_TEXT.NO_SKILLS

    const handleEdit = (item) => {
        setEdit(item)
    }

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(ENTITIES.SKILL_RECORD, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
            showActions && refetchData && refetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleCancel = () => closeConfirmationModal()

    const handleDelete = (id) =>
        showConfirmationModal({
            message: 'message.areYouSureDeleteSkill',
            title: 'general.deleteSkill',
            handleConfirm: () => handleConfirm(id),
            handleCancel,
        })

    // Group skills by category
    const groupedItems = data.reduce((acc, item) => {
        const groupBy = item['skillCategory']
        const singleItem = { ...item['skill'], skillRecordId: item.id }

        if (!acc[groupBy.priority]) {
            acc[groupBy.priority] = {
                groupBy,
                items: [],
            }
        }
        acc[groupBy.priority].items.push(singleItem)
        return acc
    }, {})

    if (isLoading && data.length === 0) {
        return <CardPlaceholder numberOfCards={profilePage ? 1 : 2} />
    }

    return (
        <Fragment>
            {data && !!data.length && (
                <div className="-mt20">
                    <CardContainer>
                        <CardHeader
                            title={t('general.skills')}
                            actions={
                                showActions
                                    ? [
                                          {
                                              handleAction: handleEdit,
                                              icon: ICONS.EDIT,
                                              iconColor: COLORS.DARK_BLUE,
                                              tooltip: 'button.editSkills',
                                          },
                                      ]
                                    : []
                            }
                        />
                        {data.length > 0 && (
                            <div className="-savedSkills -mt10">
                                {Object.values(groupedItems).map(
                                    ({ groupBy, items }) => (
                                        <div
                                            key={groupBy.id}
                                            className="-singleSkill -pb10 -mb10"
                                        >
                                            <div className="a-mediumText">
                                                {groupBy.name}
                                            </div>
                                            <ul className="-listOfSkills">
                                                {items.map((item) => (
                                                    <div
                                                        key={item.id}
                                                        className="a-status -orange -skill aligned-center"
                                                    >
                                                        <li className="label a-captionsTextRegular">
                                                            {item.name}
                                                        </li>
                                                        {showActions && (
                                                            <span
                                                                className="removeItemIcon"
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        item.skillRecordId
                                                                    )
                                                                }
                                                            ></span>
                                                        )}
                                                    </div>
                                                ))}
                                            </ul>
                                        </div>
                                    )
                                )}
                            </div>
                        )}
                    </CardContainer>
                </div>
            )}

            {!!edit && (
                <SkillRecordManage
                    data={data}
                    setOpen={setEdit}
                    fetchData={fetchData}
                    showActions={showActions}
                />
            )}

            {!showActions &&
                showNoDataText &&
                renderNoDataText(data, isLoading, t('general.noSkills'))}
        </Fragment>
    )
}

SkillRecordList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    profilePage: PropTypes.bool,
    showActions: PropTypes.bool,
    noDataText: PropTypes.string,
    refetchData: PropTypes.func,
}

SkillRecordList.defaultProps = {
    showActions: false,
}

export default SkillRecordList
