import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import useFetchData from 'hooks/useFetchData'
import ENTITIES from 'constants/entities'

export const ContractorContext = React.createContext()

const ContractorContextProvider = ({ children }) => {
    const [company, setCompany] = useState(null)

    const { profileHash } = useParams()

    const { data, isLoading, fetchData, error } = useFetchData(
        ENTITIES.COMPANY_BY_HASH,
        {
            profileHash: profileHash,
            include:
                'industries,services,user,vessels,clients,countryPhoneCode,hqCountry,size,regions,logo,heroImage,organizationTypes,locationOfServices',
        },
        !!profileHash,
        false,
        profileHash
    )

    useEffect(() => {
        setCompany(null)
    }, [profileHash])

    useEffect(() => {
        if (data && !Array.isArray(data)) {
            setCompany({
                ...data,
                id: data._id,
            })
        }
    }, [data])

    const contextValue = useMemo(
        () => ({
            company,
            setCompany,
            fetchContractor: fetchData,
            isLoadingContractor: isLoading,
            error,
        }),
        [company, isLoading]
    )

    return (
        <ContractorContext.Provider value={contextValue}>
            {children}
        </ContractorContext.Provider>
    )
}

export default ContractorContextProvider
