import { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { useLocation, useNavigate } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { formatDate } from 'services/moment.service'

import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'
import { getProjectStatusColor } from 'utils/dataFormatters'
import {
    formatDateRange,
    formatGteLteRange,
    formatPayRateRange,
} from 'utils/filterButtonFormatters'

import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import useEmptyValueMessage from 'hooks/useEmptyValueMessage'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
    INFO_VALUE_TYPE,
} from 'constants/enums'
import {
    INDUSTRY_TYPE_KEY,
    PROJECT_TYPE_KEY,
} from 'constants/projectFilterKeys'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { PROJECT_SEARCH_FILED } from 'constants/constants'

import { mockProjectData } from 'data/mockData'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import InfoRow from 'components/InfoRow'
import SearchAndFilter from 'components/SearchAndFilter'
import ProjectFilters from './ProjectFilters'
import AddNew from 'screens/diver/DiverOnboarding/AddNew'
import NoData from 'components/NoData'
import CardHeader from 'components/card/CardHeader'
import ProjectVisibilityIcon from 'components/icons/ProjectVisabilityIcon'

const filters = {
    company: { entityType: ENTITIES.COMPANY, displayAttribute: 'name' },
    country: {
        entityType: ENTITIES.COUNTRY,
        icon: ICONS.LOCATION,
        displayAttribute: 'name',
    },
    industryType: { entityType: ENTITIES.INDUSTRY, displayAttribute: 'name' },
    projectType: {
        entityType: ENTITIES.PROJECT_INDUSTRY_TYPES,
        displayAttribute: 'name',
    },
    divingMode: { entityType: ENTITIES.DIVING_MODE, displayAttribute: 'name' },
    region: { entityType: ENTITIES.REGION, displayAttribute: 'name' },
    locationType: {
        entityType: ENTITIES.LOCATION_TYPE,
        displayAttribute: 'name',
    },
    locationDetail: { type: 'text' },
    keyword: { type: 'text' },
    fromDate: { type: 'dateRange', formatter: formatDateRange },
    toDurationDays: {
        type: 'range',
        unit: 'days',
        formatter: formatGteLteRange,
    },
    positionPayRate: {
        entityType: ENTITIES.DIVER_POSITION,
        sign: '$',
        formatter: formatPayRateRange,
        fetchAttribute: 'position',
    },
}

const ProjectList = ({ userID, profilePage, companyID, myProfile }) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = useLocation()

    const { currentUser, userId, isDiver, isVerified } =
        useContext(CurrentUserContext)

    const showMockData = !isVerified && !myProfile

    const { data, meta, isLoading, fetchData, loadMore } =
        useFetchDataByQueryParams(
            ENTITIES.PROJECT_ELASTIC,
            {
                'company.id': myProfile ? null : companyID,
                myDiveProjects: myProfile || null,
                include:
                    'company,divingMode,country,locationType,projectStatus,creator',
                acceptedForUser: !myProfile && profilePage ? userID : null,
            },
            true,
            isVerified
        )

    const { activeFilters, isLoadingFilters } = useFetchActiveFilters(
        filters,
        FILTERS.DIVE_PROJECTS
    )

    const projectData = showMockData ? mockProjectData : data

    const noProjectsText = useMemo(() => {
        if (profilePage) {
            if (myProfile) {
                return !isDiver
                    ? t('general.contractorNoProjects')
                    : t('general.profileNoProjects')
            }

            return t('general.profileNoProjects')
        }

        return t('general.noProjects')
    }, [profilePage, isDiver, myProfile])

    const condition =
        projectData.length === 0 &&
        Object.keys(activeFilters).length === 0 &&
        !isLoading

    const { showEmptyValueMessage } = useEmptyValueMessage(condition)

    const handleLoadMore = () => {
        if (!isLoading && loadMore) {
            fetchData({
                page: meta.currentPage + 1,
            })
        }
    }

    const handleEdit = ({ id }) => {
        navigate(`${ROUTES.PROJECT}${ROUTES.EDIT}/${id}`)
    }

    const handleGoToCreateProject = () => {
        if (location.pathname.includes(ROUTES.MY_DIVE_PROJECTS)) {
            navigate(`${ROUTES.MY_DIVE_PROJECTS}${ROUTES.CREATE}`)
        } else {
            navigate(`${ROUTES.PROJECT}${ROUTES.CREATE}`)
        }
    }

    const handleFilterItemRemoved = (key, filters) => {
        if (
            key === INDUSTRY_TYPE_KEY &&
            filters.hasOwnProperty(PROJECT_TYPE_KEY)
        ) {
            delete filters[PROJECT_TYPE_KEY]
        }

        return filters
    }

    const handleOpenSingleProject = (item) => {
        if (location.pathname.includes(ROUTES.MY_DIVE_PROJECTS)) {
            navigate(`${ROUTES.MY_DIVE_PROJECTS}/${item.id}`)
        } else {
            navigate(`${ROUTES.PROJECT}/${item.id}`)
        }
    }

    const handleCompleteProfile = () => {
        navigate(`${ROUTES.DIVER_PROFILE}/${currentUser.profileHash}`)
    }

    return (
        <div className={showMockData ? `a-blurredContainer` : ''}>
            {profilePage ? (
                <div className="-mb20">
                    <div className="aligned-center a-tabName">
                        <span className="a-bodyTextMedium ">
                            {t('general.diveProjects')}
                        </span>
                    </div>
                    {myProfile && !isDiver && projectData.length > 0 && (
                        <AddNew
                            handleAction={handleGoToCreateProject}
                            label="button.addDiveProject"
                        />
                    )}
                </div>
            ) : (
                <SearchAndFilter
                    searchPlaceholder="general.searchProject"
                    activeFilters={activeFilters}
                    filters={filters}
                    modifyFiltersOnRemove={handleFilterItemRemoved}
                    searchKey={PROJECT_SEARCH_FILED}
                    showDefaultKey={false}
                    disabled={showMockData}
                >
                    <ProjectFilters
                        activeFilters={activeFilters}
                        myProfile={myProfile}
                        disabled={showMockData}
                    />
                </SearchAndFilter>
            )}

            {projectData.map((item, index) => (
                <div key={index} className="-mb10">
                    <CardContainer
                        link={
                            !showMockData
                                ? `${
                                      pathname.includes(ROUTES.MY_DIVE_PROJECTS)
                                          ? ROUTES.MY_DIVE_PROJECTS
                                          : ROUTES.PROJECT
                                  }/${item.id}`
                                : null
                        }
                    >
                        <CardHeader
                            title={item?.name}
                            item={item}
                            statuses={[
                                {
                                    type: 'status',
                                    name: [item?.locationTypeName],
                                    color: 'green',
                                },
                                {
                                    type: 'status',
                                    name: [item?.projectStatusName],
                                    color: getProjectStatusColor(
                                        item?.projectStatusCode
                                    ),
                                },
                            ]}
                            actions={
                                item.creatorId !== userId
                                    ? []
                                    : [
                                          {
                                              handleAction: handleEdit,
                                              icon: ICONS.EDIT,
                                              iconColor: COLORS.DARK_BLUE,
                                              tooltip: 'button.editProject',
                                          },
                                      ]
                            }
                            customIcon={
                                <ProjectVisibilityIcon
                                    creatorId={item.creatorId}
                                    hidden={item.hidden}
                                />
                            }
                        />
                        <Info
                            label="form.label.contractor"
                            value={item?.companyName}
                            companyID={item?.companyProfileHash}
                            valueType={
                                !showMockData
                                    ? INFO_VALUE_TYPE.CONTRACTOR_LINK
                                    : null
                            }
                        />
                        <Info
                            label="form.label.divingMode"
                            value={item?.divingModeName}
                            color="orange"
                        />
                        {(item?.fromDate || item?.durationTo) && (
                            <InfoRow>
                                {item?.fromDate && (
                                    <Info
                                        label="form.label.projectStart"
                                        value={formatDate(item?.fromDate)}
                                    />
                                )}
                                {item?.durationTo && (
                                    <Info
                                        label="form.label.expectedDuration"
                                        value={item?.durationTo}
                                    />
                                )}
                            </InfoRow>
                        )}
                        <InfoRow withButtons>
                            <Info
                                label="form.label.countryOfWork"
                                value={item?.countryName}
                            />
                            {!showMockData && (
                                <div>
                                    <Button
                                        label="button.viewProject"
                                        buttonSize={BUTTON_SIZE.SMALL}
                                        onClick={() =>
                                            handleOpenSingleProject(item)
                                        }
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        icon={ICONS.ARROW_RIGHT}
                                        iconColor={COLORS.LIGHT_BLUE}
                                        iconPosition={ICON_POSITION.RIGHT}
                                    />
                                </div>
                            )}
                        </InfoRow>
                    </CardContainer>
                </div>
            ))}
            {showMockData && (
                <div className="column aligned-center overlayContainer">
                    <h3>{t('general.completeYourProfile')}</h3>
                    <p className="a-bodyTextRegular a-lightText -opacity-60 text-align-center  -mt10 -mb30">
                        {t(
                            'general.completeProfileToAccessCompleteInformation'
                        )}
                    </p>
                    <Button
                        label="button.completeProfile"
                        buttonSize={BUTTON_SIZE.MEDIUM}
                        onClick={handleCompleteProfile}
                    />
                </div>
            )}
            {projectData.length === 0 &&
                Object.keys(activeFilters).length !== 0 && (
                    <NoData
                        icon={ICONS.SEARCH_ICON}
                        title={t('general.noResults')}
                        description={t('general.noSearchResultsDesc')}
                    />
                )}
            {showEmptyValueMessage &&
                Object.keys(activeFilters).length === 0 && (
                    <>
                        {Object.keys(activeFilters).length === 0 && (
                            <NoData
                                icon={ICONS.NO_DATA}
                                title={t('general.noDataResults')}
                                description={t('general.noProjectResultsDesc')}
                            />
                        )}
                        <div className="justify-center">
                            {myProfile && !isDiver && (
                                <AddNew
                                    handleAction={handleGoToCreateProject}
                                    label="button.addDiveProject"
                                />
                            )}
                        </div>
                    </>
                )}
            {!showMockData && loadMore && (
                <div className="justify-center">
                    <Button
                        label="general.loadMore"
                        type={BUTTON_TYPE.BUTTON}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        icon={ICONS.PLUS}
                        iconColor={COLORS.LIGHT_BLUE}
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={handleLoadMore}
                    />
                </div>
            )}
            {renderLoader(isLoading, profilePage, isLoadingFilters)}
        </div>
    )
}

ProjectList.propTypes = {
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    companyID: PropTypes.number,
    projectStatus: PropTypes.array,
    myProfile: PropTypes.bool,
}

ProjectList.defaultProps = {
    profilePage: false,
    myProfile: false,
}

export default ProjectList
