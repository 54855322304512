export const TAB = {
    PERSONAL_INFO: 'personalInfo',
    DIVE_PROJECTS: 'diveProjects',
    DIVE_RECORDS: 'diveRecords',
    MEDICAL_INFO: 'medicalInfo',
    TRAVEL_DOCUMENTATION: 'travelDocumentation',
    TRAINING_RECORD: 'trainingRecord',
    PERSONAL_EQUIPMENT: 'personalEquipment',
    PAST_EMPLOYMENT: 'pastEmployment',
    SKILLS: 'skills',
    EXPORT_CV: 'exportCv',
    GALLERY: 'gallery',
}

export const SUB_TAB = {
    CERTIFICATES: 'certificates',
}
