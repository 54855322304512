import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
} from 'constants/enums'

import Icon from 'components/Icon'

const Button = ({
    type,
    label,
    onClick,
    btnClass,
    disabled,
    isLoading,
    tooltip,
    icon,
    iconColor,
    iconPosition,
    iconSize,
    buttonSize,
    additionalClasses,
    gap,
}) => {
    const t = useTranslate()

    return (
        <button
            className={`a-btn -${btnClass}${
                disabled ? 'Disabled' : ''
            } ${additionalClasses} ${
                label ? '' : '-onlyIcon'
            } -${iconPosition} -h${buttonSize}`}
            type={type}
            onClick={onClick}
            disabled={disabled || isLoading}
            title={tooltip ? t(tooltip) : ''}
        >
            <div className={`aligned-center -gap${gap}`}>
                {icon && <Icon size={iconSize} name={icon} color={iconColor} />}
                {label && <span> {t(label)} </span>}
            </div>
        </button>
    )
}

Button.propTypes = {
    type: PropTypes.oneOf([
        BUTTON_TYPE.BUTTON,
        BUTTON_TYPE.SUBMIT,
        BUTTON_TYPE.RESET,
    ]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
    btnClass: PropTypes.oneOf([
        BUTTON_STATUS.PRIMARY,
        BUTTON_STATUS.SECONDARY,
        BUTTON_STATUS.TERTIARY,
        BUTTON_STATUS.QUATERNARY,
        BUTTON_STATUS.DANGER,
        BUTTON_STATUS.ICON_BUTTON,
        BUTTON_STATUS.COVER_BUTTON,
        BUTTON_STATUS.DANGER_RED,
    ]),
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    tooltip: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconSize: PropTypes.number,
    iconPosition: PropTypes.oneOf([ICON_POSITION.LEFT, ICON_POSITION.RIGHT]),
    buttonSize: PropTypes.string,
    additionalClasses: PropTypes.string,
    gap: PropTypes.number,
}

Button.defaultProps = {
    type: BUTTON_TYPE.BUTTON,
    btnClass: BUTTON_STATUS.PRIMARY,
    disabled: false,
    isLoading: false,
    iconPosition: ICON_POSITION.LEFT,
    buttonSize: BUTTON_SIZE.LARGE,
    gap: 0,
    additionalClasses: '',
}

export default Button
