import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { TAB } from './constants/tabs'
import ICONS from 'constants/icons'

import TabNavigation from 'components/tabs/TabNavigation'
import UserInfo from './components/UserInfo'

const DiverInformation = ({ myProfile, userID, user, setOpen }) => {
    const t = useTranslate()

    const {
        completionProgress: {
            personalInfo,
            medicalInfo,
            travelDocument,
            trainingRecord,
            skill,
        },
    } = user

    return (
        <div className="diverInformation">
            <div className="-box -mb20">
                <UserInfo
                    user={user}
                    myProfile={myProfile}
                    userID={userID}
                    setOpen={setOpen}
                />
            </div>

            <div className="-box">
                <p className="-mb20 a-bodyTextMedium">
                    {t('general.diverInformation')}
                </p>

                <TabNavigation
                    tabs={[
                        {
                            key: TAB.PERSONAL_INFO,
                            title: 'general.personalInfo',
                            badge: myProfile && !personalInfo,
                            icon: `${ICONS.PERSONAL_INFO}`,
                        },
                        {
                            key: TAB.DIVE_PROJECTS,
                            title: 'general.diveProjects',
                            icon: `${ICONS.DIVE_PROJECTS_OUTLINE}`,
                        },
                        {
                            key: TAB.DIVE_RECORDS,
                            title: 'general.diveRecords',
                            icon: `${ICONS.DIVE_RECORDS}`,
                        },
                        {
                            key: TAB.MEDICAL_INFO,
                            title: 'general.medicalInfo',
                            badge: myProfile && !medicalInfo,
                            icon: `${ICONS.MEDICAL_INFO}`,
                        },
                        {
                            key: TAB.TRAVEL_DOCUMENTATION,
                            title: 'general.travelDocumentation',
                            badge: myProfile && !travelDocument,
                            icon: `${ICONS.TRAVEL_DOCUMENTATION}`,
                        },
                        {
                            key: TAB.TRAINING_RECORD,
                            title: 'general.educationAndTraining',
                            badge: myProfile && !trainingRecord,
                            icon: `${ICONS.TRAINING_RECORD}`,
                        },
                        {
                            key: TAB.PERSONAL_EQUIPMENT,
                            title: 'general.personalEquipment',
                            icon: `${ICONS.PERSONAL_EQUIPMENT}`,
                        },
                        {
                            key: TAB.PAST_EMPLOYMENT,
                            title: 'general.pastEmployment',
                            icon: `${ICONS.PAST_EMPLOYMENT}`,
                        },
                        {
                            key: TAB.SKILLS,
                            title: 'general.experienceAndSkills',
                            badge: myProfile && !skill,
                            icon: `${ICONS.SKILLS}`,
                        },
                        {
                            key: TAB.GALLERY,
                            title: 'general.gallery',
                            icon: `${ICONS.GALLERY_SIDEBAR}`,
                        },
                        {
                            key: TAB.EXPORT_CV,
                            title: 'general.exportProfileAsCv',
                            icon: `${ICONS.EXPORT_CV}`,
                        },
                    ]}
                    tabQueryParam="tab"
                    columnView
                    scrollToTarget={false}
                />
            </div>
        </div>
    )
}

DiverInformation.propTypes = {
    myProfile: PropTypes.bool,
    userID: PropTypes.number,
    user: PropTypes.object,
    setOpen: PropTypes.func,
}

DiverInformation.defaultProps = {
    myProfile: false,
}

export default DiverInformation
