import { Form, Formik } from 'formik'
import useFetchData from 'hooks/useFetchData'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { persistEntityService } from 'services/entity.service'

import { AlertContext } from 'contexts/AlertContext'
import { DiveRecordContext } from '../../contexts/DiveRecordContext'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES, INPUT_FILED_TYPE } from 'constants/enums'

import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { InputField, MultiselectField } from 'components/formFields'
import TextAreaField from 'components/formFields/TextAreaField'
import FocusError from '../../../../../components/FocusError'

const SaturationEventFormModal = ({
    initialData,
    fetchData,
    closeModal,
    isSupervisor,
}) => {
    const t = useTranslate()
    const { projectId } = useParams()

    const { diveRecord, isSupervisorDiveRecord } = useContext(DiveRecordContext)
    const { setAlert } = useContext(AlertContext)
    const { data, isLoading } = useFetchData(ENTITIES.DIVERS)

    const {
        data: {
            diveRecordDiveData,
            unitImperial,
            diveRecordGeneralInformation: { divers },
        },
    } = diveRecord

    const initialValues = {
        dayInSaturation: initialData?.dayInSaturation ?? '',
        bellTeamOneMembers: initialData?.bellTeamOneMembers ?? [],
        bellTeamTwoMembers: initialData?.bellTeamTwoMembers ?? [],
        bellTeamThreeMembers: initialData?.bellTeamThreeMembers ?? [],
        bellTeamOneText: initialData?.bellTeamOneText ?? '',
        bellTeamTwoText: initialData?.bellTeamTwoText ?? '',
        bellTeamThreeText: initialData?.bellTeamThreeText ?? '',
        deepestChamberStorageDepth:
            initialData?.deepestChamberStorageDepth ?? '',
        chamberAverageTemperature: initialData?.chamberAverageTemperature ?? '',
        chamberAverageHumidity: initialData?.chamberAverageHumidity ?? '',
        comment: initialData?.comment ?? '',
    }

    const requiredMessage = t('form.error.required')

    const validateChamberAverageTemperature = () =>
        Yup.string().when([], {
            is: () => unitImperial === true,
            then: () =>
                Yup.number()
                    .integer(t('form.error.invalidNumber'))
                    .min(-20, t('form.error.minimumNumber'))
                    .max(100, t('form.error.maximumIs100')),
            otherwise: () =>
                Yup.number()
                    .integer(t('form.error.invalidNumber'))
                    .min(0, t('form.error.minimumNumberIs0'))
                    .max(37, t('form.error.maximumIs37')),
        })

    const validation = Yup.object({
        dayInSaturation: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(1, t('form.error.invalidNumber'))
            .max(60, t('form.error.maximumSixtyElements'))
            .required(requiredMessage),
        bellTeamOneMembers:
            projectId
                ? Yup.array()
                      .min(1, t('form.error.minimumOneElement'))
                      .max(4, t('form.error.maximumFourElements'))
                      .required(requiredMessage)
                : null,
        bellTeamTwoMembers:
            projectId
                ? Yup.array().max(4, t('form.error.maximumFourElements'))
                : null,
        bellTeamThreeMembers:
            projectId
                ? Yup.array().max(4, t('form.error.maximumFourElements'))
                : null,
        deepestChamberStorageDepth: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber'))
            .required(requiredMessage),
        chamberAverageTemperature: validateChamberAverageTemperature(),
        chamberAverageHumidity: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber'))
            .max(100, t('form.error.maximumIs100')),
        comment: Yup.string(),
    })

    const onSubmit = async (formData) => {
        try {
            await persistEntityService(
                ENTITIES.SATURATION_EVENT,
                {
                    ...formData,
                    bellTeamOneMembers: formData.bellTeamOneMembers.map(
                        (team1Member) => ({
                            id: team1Member.id,
                            entityType: ENTITIES.USER,
                        })
                    ),
                    bellTeamTwoMembers: formData.bellTeamTwoMembers.map(
                        (team2Member) => ({
                            id: team2Member.id,
                            entityType: ENTITIES.USER,
                        })
                    ),
                    bellTeamThreeMembers: formData.bellTeamThreeMembers.map(
                        (team3Member) => ({
                            id: team3Member.id,
                            entityType: ENTITIES.USER,
                        })
                    ),
                    diveRecordDiveData: diveRecordDiveData,
                },
                initialData ? initialData.id : null
            )
            fetchData()
            closeModal()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const bellTeamMembersParams = () => {
        return {
            project: projectId,
            ...(isSupervisorDiveRecord && {
                'divers.id': divers.map(diver => diver.id),
            }),
        }
    }

    const unitOfMeasurementLabel = unitImperial
        ? 'form.label.ft'
        : 'form.label.m'

    const unitOfMeasurementTemperature = unitImperial
        ? 'form.label.fahrenheit'
        : 'form.label.celsius'

    if (!data) return null

    if (isLoading) return <Loader />

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({
                handleSubmit,
                isSubmitting,
                values: {
                    bellTeamOneMembers,
                    bellTeamTwoMembers,
                    bellTeamThreeMembers,
                },
            }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={true}
                        setOpen={closeModal}
                        closeOnClickOutside={false}
                        title="general.saturationDailyEvent"
                        isSubmitting={isSubmitting}
                        buttons={{
                            prevBtn: {
                                label: `${
                                    isSupervisor
                                        ? 'button.closeModal'
                                        : 'button.cancel'
                                }`,
                                handleClick: closeModal,
                            },
                            nextBtn: !isSupervisor
                                ? {
                                      handleClick: isSupervisor
                                          ? closeModal
                                          : handleSubmit,
                                  }
                                : {},
                        }}
                    >
                        <fieldset disabled={isSupervisor}>
                            <div className="_wr">
                                <div className="_w">
                                    <div className="_12 _l4">
                                        <InputField
                                            name="dayInSaturation"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="_w -mt5">
                                    <div className="_12 _l4">
                                        {projectId ? (
                                            <MultiselectField
                                                name="bellTeamOneMembers"
                                                label="form.label.bellTeamOneMembers"
                                                placeholder="form.placeholder.bellTeamMembers"
                                                entityType={
                                                    ENTITIES.PROJECT_DIVERS
                                                }
                                                displayAttribute="fullName"
                                                params={bellTeamMembersParams()}
                                                disabledItems={[
                                                    ...bellTeamTwoMembers,
                                                    ...bellTeamThreeMembers,
                                                ]}
                                                searchable
                                                required={
                                                    projectId ? true : false
                                                }
                                            />
                                        ) : (
                                            <InputField
                                                name="bellTeamOneText"
                                                label="form.label.bellTeamOneMembers"
                                                placeholder="form.placeholder.bellTeamText"
                                                type={INPUT_FILED_TYPE.TEXT}
                                            />
                                        )}
                                    </div>
                                    <div className="_12 _l4">
                                        {projectId ? (
                                            <MultiselectField
                                                name="bellTeamTwoMembers"
                                                label="form.label.bellTeamTwoMembers"
                                                placeholder="form.placeholder.bellTeamMembers"
                                                entityType={
                                                    ENTITIES.PROJECT_DIVERS
                                                }
                                                displayAttribute="fullName"
                                                params={bellTeamMembersParams()}
                                                disabledItems={[
                                                    ...bellTeamOneMembers,
                                                    ...bellTeamThreeMembers,
                                                ]}
                                                searchable
                                            />
                                        ) : (
                                            <InputField
                                                name="bellTeamTwoText"
                                                label="form.label.bellTeamTwoMembers"
                                                placeholder="form.placeholder.bellTeamText"
                                                type={INPUT_FILED_TYPE.TEXT}
                                            />
                                        )}
                                    </div>
                                    <div className="_12 _l4">
                                        {projectId ? (
                                            <MultiselectField
                                                name="bellTeamThreeMembers"
                                                label="form.label.bellTeamThreeMembers"
                                                placeholder="form.placeholder.bellTeamMembers"
                                                entityType={
                                                    ENTITIES.PROJECT_DIVERS
                                                }
                                                displayAttribute="fullName"
                                                params={bellTeamMembersParams()}
                                                disabledItems={[
                                                    ...bellTeamOneMembers,
                                                    ...bellTeamTwoMembers,
                                                ]}
                                                searchable
                                            />
                                        ) : (
                                            <InputField
                                                name="bellTeamThreeText"
                                                label="form.label.bellTeamThreeMembers"
                                                placeholder="form.placeholder.bellTeamText"
                                                type={INPUT_FILED_TYPE.TEXT}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="_w -mt5">
                                    <div className="_12 _l4">
                                        <InputField
                                            name="deepestChamberStorageDepth"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units={unitOfMeasurementLabel}
                                            required
                                        />
                                    </div>
                                    <div className="_12 _l4">
                                        <InputField
                                            name="chamberAverageTemperature"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units={unitOfMeasurementTemperature}
                                        />
                                    </div>
                                    <div className="_12 _l4">
                                        <InputField
                                            name="chamberAverageHumidity"
                                            type={INPUT_FILED_TYPE.NUMBER}
                                            units="form.label.relativeHumidity"
                                        />
                                    </div>
                                </div>
                                <div className="_w -mt5">
                                    <div className="_12">
                                        <TextAreaField
                                            name="comment"
                                            largeTextarea
                                        />
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default SaturationEventFormModal
