import { Form, Formik, useFormikContext } from 'formik'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { createEntityService, editEntityService } from 'services/entity.service'

import { EXPERIENCE_OPTIONS } from 'constants/constants'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    INPUT_FILED_TYPE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'

import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { InputField, RadioGroupField, SelectField } from 'components/formFields'
import { DIVING_MODES } from 'screens/diver/diveRecord/constants/divingModes'
import CreateExperience from './CreateExperience'

const AddExperienceModalContext = () => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext
    const { experienceOption } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }
        if (!firstRender.current) {
            resetField(`totalYears`, '')
            resetField(`divingMode`, '')
        } else {
            firstRender.current = false
        }
    }, [experienceOption])

    const resetField = (field, defaultValue) => {
        setFieldValue(field, defaultValue)
        setFieldTouched(field, false)
    }
}

const AddExperienceModal = ({
    open,
    setOpen,
    managingExperiences,
    setManagingExperiences,
    fetchData,
    divingModes,
    showActions,
    refetchData,
    canCreateTotalYearsOfExperience,
    editMode,
    itemForEdit,
}) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)
    const [openCreateExperienceModal, setOpenCreateExperienceModal] = useState(
        false
    )
    const [choosedDivingMode, setChoosedDivingMode] = useState(null)

    const enabledDivingModes = () => {
        return divingModes.filter(
            (mode) => mode.code !== DIVING_MODES.CHAMBER_RECOMPRESSION_ONLY.code
        )
    }

    const requiredMessage = t('form.error.required')

    const initialValues = {
        experienceOption: canCreateTotalYearsOfExperience ? 0 : 1,
        totalYears:
            editMode && itemForEdit ? itemForEdit?.totalYears ?? '' : '',
        divingMode: null,
    }

    const validation = Yup.object({
        experienceOption: Yup.number(),
        totalYears: Yup.number().when('experienceOption', {
            is: (val) => val === EXPERIENCE_OPTIONS[0].id,
            then: () =>
                Yup.number()
                    .integer(t('form.error.invalidNumber'))
                    .min(0, t('form.error.invalidNumber'))
                    .max(99, t('form.error.maxNumberIs99'))
                    .required(requiredMessage),
            otherwise: () =>
                Yup.number()
                    .integer(t('form.error.invalidNumber'))
                    .min(0, t('form.error.invalidNumber'))
                    .max(99, t('form.error.maxNumberIs99')),
        }),
        divingMode: Yup.object().when('experienceOption', {
            is: (val) => !editMode && val === EXPERIENCE_OPTIONS[1].id,
            then: () => Yup.object().required(requiredMessage),
            otherwise: () => Yup.object().nullable(),
        }),
    })

    const onSubmit = async (formData, { setSubmitting }) => {
        try {
            setSubmitting(true)
            if (
                formData.experienceOption === EXPERIENCE_OPTIONS[1].id &&
                !editMode
            ) {
                setOpenCreateExperienceModal(true)
                setChoosedDivingMode(formData.divingMode)
            } else if (editMode) {
                await editEntityService(
                    ENTITIES.EXPERIENCE,
                    itemForEdit.id,
                    formData
                )
                setOpen(false)
                fetchData()
            } else {
                await createEntityService(ENTITIES.EXPERIENCE, formData, true)
                setOpen(false)
                fetchData()
            }
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    if (editMode && !itemForEdit) return null
    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmit}
            >
                {({ isSubmitting, values: { experienceOption } }) => {
                    return (
                        <Form>
                            <Modal
                                open={open}
                                setOpen={setOpen}
                                closeOnClickOutside={false}
                                title={
                                    editMode
                                        ? 'general.editExperience'
                                        : 'general.addExperience'
                                }
                                smallModalWithDropdowns
                                isSubmitting={isSubmitting}
                                smallModal
                                buttons={{
                                    nextBtn: {
                                        label: editMode
                                            ? 'button.saveExperience'
                                            : experienceOption ===
                                              EXPERIENCE_OPTIONS[0].id
                                            ? 'button.saveExperience'
                                            : 'button.continue',
                                    },
                                }}
                            >
                                {editMode ? (
                                    <div className="_wr">
                                        <div className="-form _w">
                                            <div className="_12">
                                                <InputField
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    name="totalYears"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <Fragment>
                                        <AddExperienceModalContext />
                                        <div className="_wr">
                                            <div className="-form _w">
                                                <div className="fullWidth">
                                                    <div className="_12">
                                                        <RadioGroupField
                                                            name="experienceOption"
                                                            defaultOptions={EXPERIENCE_OPTIONS.map(
                                                                (option) => ({
                                                                    ...option,
                                                                    disabled:
                                                                        option.id ===
                                                                            0 &&
                                                                        !canCreateTotalYearsOfExperience,
                                                                })
                                                            )}
                                                            valueType={
                                                                SELECT_VALUE_TYPE.STRING
                                                            }
                                                        />
                                                    </div>
                                                    {experienceOption ===
                                                    EXPERIENCE_OPTIONS[0].id ? (
                                                        <div className="_12">
                                                            <InputField
                                                                type={
                                                                    INPUT_FILED_TYPE.NUMBER
                                                                }
                                                                name="totalYears"
                                                                required
                                                                disabled={
                                                                    !canCreateTotalYearsOfExperience
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="_12">
                                                            <SelectField
                                                                name="divingMode"
                                                                label="form.label.divingMode"
                                                                placeholder="form.placeholder.divingMode"
                                                                defaultOptions={enabledDivingModes()}
                                                                searchable
                                                                required
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                                {isSubmitting && <Loader />}
                            </Modal>
                        </Form>
                    )
                }}
            </Formik>
            {openCreateExperienceModal && (
                <CreateExperience
                    open={managingExperiences}
                    setOpen={setManagingExperiences}
                    fetchData={fetchData}
                    divingModes={divingModes}
                    showActions={showActions}
                    refetchData={refetchData}
                    divingMode={choosedDivingMode}
                />
            )}
        </div>
    )
}

export default AddExperienceModal
