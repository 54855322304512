import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import useCanLeavePage from 'hooks/useCanLeavePage'

import ROUTES from 'constants/routes'
import { ACTION } from 'constants/constants'

const Footer = () => {
    const t = useTranslate()
    const { canLeavePage, handleShowModal } = useCanLeavePage()

    const handleOpenModal = (action, route) => {
        if (!canLeavePage && action === ACTION.NAVIGATE) {
            handleShowModal(route)
        } else if (!canLeavePage) {
            handleShowModal(null)
        }
    }

    const FOOTER_MENU_ITEMS = [
        {
            key: 'support',
            to: `${ROUTES.HELP_AND_INFO}/${ROUTES.SUPPORT}`,
            blank: false,
        },
        {
            key: 'wiki',
            to: `${ROUTES.HELP_AND_INFO}`,
            blank: false,
        },
        {
            key: 'termsOfService',
            to: 'https://skillndepth.com/terms-of-service',
            blank: true,
        },
        {
            key: 'privacyPolicy',
            to: 'https://skillndepth.com/privacy-policy',
            blank: true,
        },
    ]

    return (
        <footer className="m-footer">
            <div className="_wr">
                <div className="_w ">
                    <div className="_12 m-footer__policy">
                        <div className="a-captionsTextRegular">
                            Skill N Depth &copy; {new Date().getFullYear()} | v
                            1.2.3
                        </div>
                        <div className="m-footer__policy--links">
                            {FOOTER_MENU_ITEMS.map((item, index) => (
                                <Link
                                    to={
                                        canLeavePage || item.blank
                                            ? item.to
                                            : undefined
                                    }
                                    key={index}
                                    className="a-captionsTextRegular"
                                    target={item.blank ? '_blank' : ''}
                                    onClick={() =>
                                        canLeavePage || item.blank
                                            ? undefined
                                            : handleOpenModal(
                                                  ACTION.NAVIGATE,
                                                  item.to
                                              )
                                    }
                                >
                                    {t(`footer.${item.key}`)}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
