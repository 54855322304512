import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { getEntityService } from 'services/entity.service'

import renderLoader from '../../utils/loaderHelper'

import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'

import AddNew from '../../AddNew'
import ExperienceList from './ExperienceList'
import AddExperienceModal from './AddExperienceModal'
import NoData from 'components/NoData'

const ExperienceWrapper = ({
    data,
    isLoading,
    fetchData,
    title,
    profilePage,
    showActions,
    noDataText,
    refetchData,
}) => {
    const t = useTranslate()

    const [managingExperiences, setManagingExperiences] = useState(false)
    const [divingModes, setDivingModes] = useState([])

    const canCreateTotalYearsOfExperience =
        data.filter((item) => item?.totalYears || item.totalYears === 0)
            .length === 0

    useEffect(() => {
        getEntityService(ENTITIES.DIVING_MODE).then((response) => {
            setDivingModes(response.data)
        })
    }, [])

    const enabledDivingModes = divingModes.filter(
        (mode) => !data.some((item) => item?.divingMode?.id === mode.id)
    )

    return (
        <>
            {!profilePage && (
                <h2 className="a-bodyTextMedium">{title && t(title)}</h2>
            )}

            {showActions && (
                <AddNew
                    handleAction={() => {
                        setManagingExperiences(true)
                    }}
                    label="general.addExperience"
                />
            )}
            {!showActions && data.length === 0 && (
                <NoData
                    icon={ICONS.NO_DATA}
                    title={t('general.noDataResults')}
                    description={t('general.noProjectResultsDesc')}
                />
            )}
            {managingExperiences && (
                <AddExperienceModal
                    open={managingExperiences}
                    setOpen={setManagingExperiences}
                    managingExperiences={managingExperiences}
                    setManagingExperiences={setManagingExperiences}
                    fetchData={fetchData}
                    divingModes={enabledDivingModes}
                    showActions={showActions}
                    refetchData={refetchData}
                    canCreateTotalYearsOfExperience={
                        canCreateTotalYearsOfExperience
                    }
                />
            )}

            <ExperienceList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                divingModes={divingModes}
                profilePage={profilePage}
                showActions={showActions}
                noDataText={noDataText}
                refetchData={refetchData}
            />

            {renderLoader(isLoading, profilePage)}
        </>
    )
}

ExperienceWrapper.propTypes = {
    profilePage: PropTypes.bool,
    showActions: PropTypes.bool,
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    title: PropTypes.string,
    noDataText: PropTypes.string,
    refetchData: PropTypes.func,
}

ExperienceWrapper.defaultProps = {
    profilePage: false,
    showActions: false,
    title: '',
}

export default ExperienceWrapper
