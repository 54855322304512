import { useCallback, useContext, useEffect } from 'react'
import { StreamChat } from 'stream-chat'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import axios, { BASE_URL, GET } from 'utils/axiosClient'
import { getUserId } from 'services/localStorage.service'

import { FEATURES } from 'constants/features'

import usePremiumFeature from './usePremiumFeature'

const useChat = (fullScreen) => {
    const apiKey = process.env.REACT_APP_CHAT_API_KEY

    const { hasFeatureAccess } = usePremiumFeature()
    const hasChatAccess = hasFeatureAccess(FEATURES.CHAT)

    const {
        currentUser,
        currentCompany,
        chatClient,
        setChatClient,
        setUnreadMessages,
        isVerified,
    } = useContext(CurrentUserContext)

    const tokenProvider = useCallback(() => fetchToken(), [currentUser])
    const userId = getUserId()

    const buildData = () => {
        let data = {
            id: userId.toString(),
            name: currentUser.fullName,
        }

        const img = currentUser?.thumbnails.md || currentCompany?.thumbnails.md

        if (img) {
            data.image = `${BASE_URL}${img}`
        }

        return data
    }

    const fetchToken = () => {
        if (currentUser) {
            return axios(GET, '/chat/token', null, false).then(
                (response) => response.data
            )
        } else {
            return Promise.reject()
        }
    }

    const connectChatClient = async (client = chatClient) => {
        const user = await client.connectUser(buildData(), tokenProvider)
        setChatClient(client)
        setUnreadMessages(user.me.unread_channels)
    }

    const disconnectChatClient = async () => {
        await chatClient.disconnectUser()
        setChatClient(chatClient)
    }

    useEffect(() => {
        const canConnectChatClient =
            !fullScreen &&
            currentUser &&
            currentUser.fullName &&
            isVerified &&
            hasChatAccess

        if (canConnectChatClient && !chatClient) {
            const client = new StreamChat.getInstance(apiKey)
            connectChatClient(client)
        }

        if (canConnectChatClient && chatClient && !chatClient.user) {
            connectChatClient()
        }
    }, [currentUser])

    useEffect(() => {
        if (!chatClient) return

        const handleChangeMessageCount = (event) => {
            const eventTypes = [
                'notification.message_new',
                'notification.mark_read',
                'notification.mark_unread',
                'message.new',
            ]
            if (eventTypes.includes(event.type)) {
                setUnreadMessages(event.unread_channels)
            }
        }

        const messageEventListener = chatClient.on((event) =>
            handleChangeMessageCount(event)
        )

        return () => {
            messageEventListener.unsubscribe()
        }
    }, [chatClient])

    return {
        client: chatClient,
        currentUser,
        connectChatClient,
        disconnectChatClient,
    }
}

export default useChat
