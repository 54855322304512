import React from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { BUTTON_STATUS, BUTTON_TYPE, SELECT_VALUE_TYPE } from 'constants/enums'
import ENTITIES from 'constants/entities'

import SelectField from 'components/formFields/SelectField'
import InputField from 'components/formFields/InputField'
import Button from 'components/Button'
import MultiselectField from 'components/formFields/MultiselectField'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'

const CompanyInfo = ({ companyData, setCompanyData, setStep }) => {
    const t = useTranslate()

    const initialValues = {
        name: companyData.name ?? '',
        hqAddress: companyData.hqAddress ?? '',
        size: companyData.size ?? null,
        industries: companyData.industries ?? [],
        organizationTypes: companyData.organizationTypes ?? [],
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        name: Yup.string()
            .trim()
            .max(255, t('form.error.maximumCharacters'))
            .required(requiredMessage),
        hqAddress: Yup.string()
            .trim()
            .max(255, t('form.error.maximumCharacters'))
            .required(requiredMessage),
        size: Yup.object().required(requiredMessage),
        industries: Yup.array()
            .min(1, t('form.error.industries'))
            .required(requiredMessage),
        organizationTypes: Yup.array()
            .min(1, t('form.error.categories'))
            .required(requiredMessage),
    })

    const handleSubmit = (formData) => {
        setCompanyData((data) => ({ ...data, ...formData }))
        setStep((step) => step + 1)
    }

    return (
        <div className="m-form -login">
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <div className="_w">
                            <div className="_l12">
                                <h1 className="-title -mb40">
                                    {t('general.moreAboutCompany')}
                                </h1>
                                <InputField
                                    name="name"
                                    label="form.label.companyName"
                                    placeholder="form.placeholder.enterCompanyName"
                                    required
                                />
                                <InputField
                                    name="hqAddress"
                                    label="form.label.headquarter"
                                    placeholder="form.placeholder.enterHeadquarter"
                                    required
                                />
                                <SelectField
                                    name="size"
                                    label="form.label.companySize"
                                    placeholder="form.placeholder.companySize"
                                    entityType={ENTITIES.COMPANY_SIZE}
                                    searchable
                                    required
                                />
                                <MultiselectField
                                    name="industries"
                                    label="form.label.industriesYourOrganisationServes"
                                    placeholder="form.placeholder.selectIndustriesYourOrganisationServes"
                                    entityType={ENTITIES.INDUSTRY}
                                    valueType={SELECT_VALUE_TYPE.OBJECT}
                                    searchable
                                    createNew
                                    required
                                />
                                <MultiselectField
                                    name="organizationTypes"
                                    label="form.label.categories"
                                    placeholder="form.placeholder.categories"
                                    entityType={ENTITIES.ORGANIZATION_TYPES}
                                    valueType={SELECT_VALUE_TYPE.OBJECT}
                                    searchable
                                    required
                                />
                                <div className="-mt30">
                                    <Button
                                        btnClass={BUTTON_STATUS.PRIMARY}
                                        type={BUTTON_TYPE.SUBMIT}
                                        label="button.next"
                                        disabled={isSubmitting}
                                    />
                                </div>
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

CompanyInfo.propTypes = {
    companyData: PropTypes.object,
    setCompanyData: PropTypes.func.isRequired,
    setStep: PropTypes.func.isRequired,
}

export default CompanyInfo
