import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'

import ENTITIES from 'constants/entities'

import useFetchData from 'hooks/useFetchData'

const ContractorHomepageStatistics = () => {
    const t = useTranslate()

    const { data: statistics } = useFetchData(
        ENTITIES.COMPANY_METRICS,
        {},
        true,
        false
    )

    if (!statistics || statistics.id !== 'company_metrics') {
        return null
    }

    const {
        diversOnTheJob,
        projectsInProgress,
        totalDiveRecords,
        upcomingProjects,
    } = statistics

    return (
        <Fragment>
            <span className="a-bodyTextMedium">
                {t('general.companyMetrics')}
            </span>

            <div className="a-dataGrid -mt20">
                <div>
                    <div className="a-dataGrid__cell -borderBottom">
                        <span className="a-mediumText a-lightText -opacity-60">
                            {t('general.diversOnTheJob')}
                        </span>
                        <span className="a-status -mt10 -green a-bodyTextMedium">
                            {diversOnTheJob}
                        </span>
                    </div>

                    <div className="a-dataGrid__cell">
                        <span className="a-mediumText a-lightText -opacity-60">
                            {t('general.totalDiveRecords')}
                        </span>
                        <span className="a-status -mt10 -blue a-bodyTextMedium">
                            {totalDiveRecords}
                        </span>
                    </div>
                </div>
                <div className="">
                    <div className="a-dataGrid__cell -borderBottom">
                        <span className="a-mediumText a-lightText -opacity-60">
                            {t('general.projectsInProgress')}
                        </span>
                        <span className="a-status -mt10 -orange a-bodyTextMedium">
                            {projectsInProgress}
                        </span>
                    </div>
                    <div className="a-dataGrid__cell">
                        <span className="a-mediumText a-lightText -opacity-60">
                            {t('general.upcomingProjects')}
                        </span>
                        <span className="a-status -mt10 -purple a-bodyTextMedium">
                            {upcomingProjects}
                        </span>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ContractorHomepageStatistics
