import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import useWindowDimensions from 'hooks/useWindowDimension'

import Breadcrumbs from 'components/Breadcrumbs'
import { ProjectList } from 'components/projects'
import Button from 'components/Button'
import ContractorHomepageStatistics from '../../../components/contractorHomepageStatistics/ContractorHomepageStatistics'
import { BUTTON_SIZE } from 'constants/enums'

const MyDiveProjects = () => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { isSmallScreen } = useWindowDimensions()

    const { currentUser, currentCompany, isContractor } = useContext(
        CurrentUserContext
    )

    if (!currentUser || (isContractor && !currentCompany)) return null

    return (
        <div>
            <div className="m-boxes">
                <div className="m-boxes__breadcrumbs">
                    <Breadcrumbs
                        breadcrumbs={[
                            {
                                label: 'general.myDiveProjects',
                                to: ROUTES.MY_DIVE_PROJECTS,
                                icon: ICONS.HOMEPAGE_BREADCRUMB,
                            },
                        ]}
                    />
                </div>
                <div
                    className={`m-boxes__main ${
                        isContractor ? '' : '-fullWidth'
                    }`}
                >
                    <h3 className="-mb20">{t('general.myDiveProjects')}</h3>
                    <ProjectList myProfile={true} />
                </div>

                {isContractor && (
                    <div
                        className={`m-boxes__side -button ${
                            isSmallScreen ? '-mt0' : ''
                        }`}
                    >
                        <div
                            className={`m-boxes__side ${
                                isSmallScreen ? '-mt20' : ''
                            }`}
                        >
                            <h6 className="a-bodyTextMedium -mb20">
                                {t('general.createProjectDescription')}
                            </h6>

                            <Button
                                label={'general.createProject'}
                                onClick={() => {
                                    navigate(
                                        `${ROUTES.PROJECT}${ROUTES.CREATE}`
                                    )
                                }}
                                buttonSize={BUTTON_SIZE.MEDIUM}
                            />
                        </div>

                        <div className="m-boxes__side -textContent -mt20">
                            <ContractorHomepageStatistics />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default MyDiveProjects
