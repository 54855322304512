import PropTypes from 'prop-types'
import {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useField } from 'formik'
import { useTranslate } from 'react-polyglot'
import * as Dropzone from 'react-dropzone/dist/index'
import heic2any from 'heic2any'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { AlertContext } from 'contexts/AlertContext'

import axios, { BASE_FILE_URL, POST } from 'utils/axiosClient'
import { formatResponseData } from 'utils/jsonApiFormatters'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import {
    ALERT_TYPES,
    AVAILABILITY,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_SIZE,
} from 'constants/enums'

import FieldError from 'components/FieldError'
import Icon from 'components/Icon'
import Button from 'components/Button'
import IconButton from 'components/IconButton'
import ListOfStatusesWithTooltip from 'components/ListOfStatusesWithTooltip'

const ProfilePictureUploadField = ({
    name,
    entityType,
    isJSONAPI,
    apiUrl,
    reload,
    disabled,
    signUp,
    handleSubmit,
    userName,
    positions,
    location,
    profilePage,
    availability,
    values,
    formProps,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const [openProfileImageDropdown, setOpenProfileImageDropdown] =
        useState(false)

    const hamBtnRef = useRef(null)

    const [field, meta, helpers] = useField(name)

    const { value } = field
    const { setValue } = helpers
    const [uploaded, setUploaded] = useState(value)

    const error = meta.touched && meta.error
    const canDelete = !!uploaded

    const handleClickOutside = (event) => {
        if (!hamBtnRef.current.contains(event.target)) {
            setOpenProfileImageDropdown(false)
        }
    }

    const renderStatusClassName = (userStatus) => {
        switch (userStatus) {
            case AVAILABILITY.AVAILABLE_FOR_HIRE:
                return '-available'
            case AVAILABILITY.UNAVAILABLE:
                return '-unavailable'
            case AVAILABILITY.EMPLOYED:
                return '-employed'
            default:
                return '-noStatus'
        }
    }

    const handleDeleteModal = (e) => {
        showConfirmationModal({
            title: 'general.removeProfilePhoto',
            message: 'general.areYouSureRemoveProfilePhoto',
            handleConfirm: () => handleDeleteSingleItem(e),
            handleCancel: () => closeConfirmationModal(),
        })
    }

    const handleDeleteSingleItem = (e) => {
        e.stopPropagation()
        setUploaded(null)
        closeConfirmationModal()
    }

    const onDrop = useCallback(
        async (acceptedFiles) => {
            for (const element of acceptedFiles) {
                if (element.type.startsWith('image/')) {
                    const formData = new FormData()
                    let acceptedData = element
                    let fileName = element.name

                    if (['image/heic', 'image/heif'].includes(element.type)) {
                        // Convert .heic image to .jpg format
                        const convertedImage = await heic2any({
                            blob: element,
                            toType: 'image/jpeg', // Convert to JPEG format
                        })
                        acceptedData = convertedImage
                        fileName = element.name.replace('.heic', '.jpeg')
                    }

                    formData.append('file', acceptedData, fileName)
                    formData.append('isPublic', true)
                    axios(
                        POST,
                        `/${ENTITIES.IMAGE}/upload`,
                        formData,
                        isJSONAPI,
                        apiUrl
                    )
                        .then(async ({ data }) => {
                            let file = data

                            if (isJSONAPI) {
                                const { data: uploadedFile } =
                                    formatResponseData(data)
                                file = uploadedFile
                            }

                            setUploaded(file)
                        })
                        .catch((error) => {
                            setAlert(error, ALERT_TYPES.ERROR, t)
                        })
                } else {
                    setAlert(t('message.imageFiles'), ALERT_TYPES.ERROR)
                }
            }
        },
        [setValue]
    )

    const { getRootProps, getInputProps } = Dropzone.useDropzone({
        onDrop,
        disabled,
    })

    const displayEditButton = () => (
        <Fragment>
            <div
                className={`-edit ${
                    signUp && !uploaded ? '-hidden' : '-visible'
                } ${signUp ? '-signUp' : ''}`}
            >
                <IconButton
                    icon={ICONS.EDIT_USER_PROFILE}
                    onClick={(e) => {
                        e.preventDefault()
                        setOpenProfileImageDropdown(!openProfileImageDropdown)
                    }}
                />
                {uploaded && (
                    <div className="-tooltip a-captionsTextRegular">
                        {t('general.editPhoto')}
                    </div>
                )}
            </div>

            <ul
                ref={hamBtnRef}
                className={`m-avatar__popup ${
                    openProfileImageDropdown ? '-open' : ''
                } `}
            >
                <li {...getRootProps()} className="m-avatar__item a-mediumText">
                    <input {...getInputProps()} />
                    <Icon
                        name={ICONS.UPLOAD}
                        color={COLORS.LIGHT_BLUE}
                        size={ICON_SIZE.SIZE22}
                    />
                    {t('button.uploadNewPhoto')}
                </li>
                <li
                    className={`${
                        !canDelete ? '-disabled' : ''
                    } m-avatar__item a-mediumText`}
                    onClick={(e) =>
                        uploaded && canDelete ? handleDeleteModal(e) : ''
                    }
                >
                    <Icon
                        name={ICONS.DELETE}
                        color={COLORS.LIGHT_BLUE}
                        size={ICON_SIZE.SIZE22}
                    />
                    {t('button.removeCurrentPhoto')}
                </li>
            </ul>
        </Fragment>
    )

    const displayProfileImage = () => (
        <Fragment>
            <div className="m-profilePictureUploader--placeholder">
                <div className="-placeholderImage">
                    {signUp ? (
                        <Icon
                            name={ICONS.PHOTO}
                            color={COLORS.DARK_BLUE_40}
                            size={ICON_SIZE.SIZE36}
                        />
                    ) : (
                        <Icon
                            name={ICONS.USER}
                            color={COLORS.WHITE}
                            size={ICON_SIZE.SIZE80}
                        />
                    )}
                </div>
            </div>
            <div
                className={`m-profilePictureUploader--image ${
                    uploaded?.path ? '-hasImage' : ''
                }`}
                {...(uploaded?.path && {
                    style: {
                        backgroundImage: `url(${
                            BASE_FILE_URL + uploaded?.path
                        })`,
                    },
                })}
            >
                {availability && (
                    <div
                        className={`-status ${renderStatusClassName(
                            availability
                        )}`}
                    ></div>
                )}
                {displayEditButton()}
            </div>
        </Fragment>
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        setValue(uploaded)
        setOpenProfileImageDropdown(false)
    }, [uploaded])

    useEffect(() => {
        setUploaded(value)
    }, [reload])

    return (
        <div className={`${profilePage ? '_12 _m3 column' : 'fullWidth'}`}>
            <div
                className={`m-addAndUpload m-profilePictureUploader ${
                    disabled ? '-disabled' : ''
                } ${signUp ? '-signUp' : ''}`}
            >
                {signUp && !uploaded ? (
                    <div className="-profileImageBox">
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {displayProfileImage()}
                        </div>
                        {signUp && (
                            <div className="-tooltip a-captionsTextRegular">
                                {t('general.uploadPhoto')}
                            </div>
                        )}
                        {displayEditButton()}
                    </div>
                ) : (
                    <Fragment>{displayProfileImage()}</Fragment>
                )}

                {signUp && (
                    <div className="column aligned-center -mt20">
                        <h3>{userName}</h3>
                        <div className="-mt10 -positions aligned-center justify-center">
                            {positions && (
                                <ListOfStatusesWithTooltip
                                    positions={Object.values(positions)}
                                    count={3}
                                    statusColor="-gray"
                                />
                            )}
                        </div>
                        <div className="aligned-center -mt10 -location">
                            <Icon
                                name={ICONS.LOCATION}
                                color={COLORS.DARK_BLUE_20}
                            />
                            <span className="a-bodyTextRegular a-lightText -opacity-60">
                                {location.name}
                            </span>
                        </div>
                    </div>
                )}
                {signUp && (
                    <span
                        className={`a-blueText a-bodyTextRegular -skipLink -mt20 ${
                            disabled ? '-disabled' : ''
                        }`}
                        onClick={() => {
                            if (!disabled) {
                                handleSubmit(values, formProps)
                            }
                        }}
                    >
                        {t('button.skipAndFinishLater')}
                    </span>
                )}
                {signUp && uploaded && (
                    <div className="-mt30 -signUpContent">
                        <Button
                            btnClass={BUTTON_STATUS.PRIMARY}
                            type={BUTTON_TYPE.SUBMIT}
                            label="button.finish"
                            disabled={disabled}
                            onClick={handleSubmit}
                        />
                    </div>
                )}
                {error && (
                    <FieldError
                        className="errorMessage _12 -mt10"
                        error={error}
                    />
                )}
            </div>
        </div>
    )
}

ProfilePictureUploadField.propTypes = {
    name: PropTypes.string.isRequired,
    entityType: PropTypes.string,
    isJSONAPI: PropTypes.bool,
    apiUrl: PropTypes.string,
    disabled: PropTypes.bool,
    reload: PropTypes.bool,
    profilePage: PropTypes.bool,
    values: PropTypes.object,
    formProps: PropTypes.object,
}

ProfilePictureUploadField.defaultProps = {
    entityType: ENTITIES.FILE,
    isJSONAPI: true,
    disabled: false,
    profilePage: false,
}

export default ProfilePictureUploadField
