import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import ROUTES from 'constants/routes'
import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
} from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Button from 'components/Button'
import IconButton from 'components/IconButton'
import { useNavigate } from 'react-router-dom'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { useRemoveFromList } from 'hooks/useRemoveUser'
import CardContainer from 'components/CardContainer'
import CardHeader from 'components/card/CardHeader'

const FavoriteUsersList = ({
    favoriteDivers,
    isLoadingFavorites,
    loadMore,
    setDivers,
    fetchFavorites,
    divers,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const removeUser = useRemoveFromList()

    const { currentCompany } = useContext(CurrentUserContext)

    const contractorProfileHash = currentCompany?.profileHash

    const handleViewFavoriteUsers = () => {
        navigate(
            `${ROUTES.DIVING_CONTRACTOR_PROFILE}/${contractorProfileHash}/?tab=favoriteUsers`
        )
    }

    return (
        <div className="column">
            <span className="a-bodyTextMedium -boxTitle">
                {t('general.yourFavoriteUsers')}
            </span>

            {favoriteDivers.map((item, index) => {
                return (
                    <div key={index} className="fullWidth -mt20">
                        <CardContainer
                            noBorderCard
                            link={`${ROUTES.DIVER_PROFILE}/${item?.profileHash}`}
                            withButton
                        >
                            <CardHeader
                                title={item.fullName}
                                subtitle={[item.countryName, item.role]}
                                smallerFontSize
                                avatar={item.avatarPath}
                                placeholderIcon={ICONS.USER}
                                verifiedBadge={item.identityVerified}
                                actions={[
                                    {
                                        handleAction: () =>
                                            removeUser(
                                                item,
                                                divers,
                                                setDivers,
                                                fetchFavorites
                                            ),
                                        icon: ICONS.FAVORITES,
                                        iconColor: item.favoriteUser
                                            ? COLORS.LIGHT_BLUE
                                            : COLORS.WHITE,
                                    },
                                ]}
                            />
                        </CardContainer>
                    </div>
                )
            })}

            {favoriteDivers.length === 0 && !isLoadingFavorites && (
                <span className="a-captionsTextRegular a-lightText -mt20">
                    {t('general.noFavoriteUsersSidebar')}
                </span>
            )}
            {loadMore && (
                <div className="justify-center -mt20">
                    <Button
                        label="button.viewMore"
                        type={BUTTON_TYPE.BUTTON}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        buttonSize={BUTTON_SIZE.XSMALL}
                        icon={ICONS.ARROW_RIGHT}
                        iconColor={COLORS.LIGHT_BLUE}
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={() => {
                            handleViewFavoriteUsers()
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default FavoriteUsersList
