import orionMarineImg from 'assets/images/orionMarine.png'
import smithRowe from 'assets/images/smithRowe.png'
import marineSolutions from 'assets/images/marineSolutions.png'
import hollandDiving from 'assets/images/hollandDiving.png'

const mockProjectData = [
    {
        id: 1,
        name: 'Conventional Aqua Quest',
        locationTypeName: 'Inland',
        projectStatusName: 'In progress / Executing',
        companyName: 'SeaQuest Diving Services',
        divingModeName: 'SCUBA Conventional',
        fromDate: '2024-02-01',
        countryName: 'Cayman Islands',
    },
    {
        id: 2,
        name: 'Oceanic Discoveries',
        locationTypeName: 'Inland',
        projectStatusName: 'Mobilizing / Preparing',
        companyName: 'Deep Blue Divers',
        divingModeName: 'Closed Bell Bounce',
        countryName: 'Egypt',
    },
    {
        id: 3,
        name: 'HydroDiscover Project',
        locationTypeName: 'Near shore',
        projectStatusName: 'Planning',
        companyName: 'Blue Horizon Scuba',
        divingModeName: 'SCUBA Conventional',
        countryName: 'Cyprus',
    },
]

const mockPopularDivingOrganizations = [
    {
        id: 1,
        companyName: 'Orion Marine Group',
        hqLocationCountry: 'USA, Florida',
        image: orionMarineImg,
        industries: [
            {
                id: 1,
                value: 'Oil and Gas',
            },
        ],
    },
    {
        id: 2,
        companyName: 'Smith Rowe, LLC',
        hqLocationCountry: 'USA, North Carolina',
        image: smithRowe,
        industries: [
            {
                id: 1,
                value: 'Environmental Services',
            },
        ],
    },
    {
        id: 3,
        companyName: 'Marine Solutions, Inc.',
        hqLocationCountry: 'USA, California',
        image: marineSolutions,
        industries: [
            {
                id: 1,
                value: 'Marine Salvage',
            },
        ],
    },
    {
        id: 4,
        companyName: 'Holland Diving International',
        hqLocationCountry: 'USA, Ohio',
        image: hollandDiving,
        industries: [
            {
                id: 1,
                value: 'Renewable Energy',
            },
        ],
    },
]

const mockTopJobPics = [
    {
        id: 1,
        role: 'Diver',
        projectName: 'Conventional Aqua Quest',
        companyName: 'Orion Marine Group',
        geographicalRegion: 'USA',
        countryOfWork: 'Florida',
        fromPayRate: 200,
        toPayRate: 400,
        skillPack: [
            'Navigation',
            'Non-destructive testing',
            'Saturation diving',
        ],
    },
    {
        id: 2,
        role: 'Equipment technician',
        projectName: 'HydroDiscover Project',
        companyName: 'Subsea Global Solutions',
        geographicalRegion: 'USA',
        countryOfWork: 'Florida',
        fromPayRate: 250,
        toPayRate: 380,
        skillPack: ['Navigation', 'Non-destructive testing'],
    },
]

export { mockProjectData, mockPopularDivingOrganizations, mockTopJobPics }
