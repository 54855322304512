import {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useTranslate } from 'react-polyglot'
import { useDropzone } from 'react-dropzone'
import { Gallery } from 'react-grid-gallery'

import Lightbox from 'yet-another-react-lightbox'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/styles.css'

import { deleteEntityService } from 'services/entity.service'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useFetchData from 'hooks/useFetchData'
import useWindowDimensions from 'hooks/useWindowDimension'
import { useUppy } from 'hooks/useUppy'

import { BASE_FILE_URL } from 'utils/axiosClient'

import {
    MAX_NUMBER_OF_PICTURES_FOR_UPLOAD,
    UPLOAD_STATUS,
} from './constants/galleryConstants'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_SIZE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Button from 'components/Button'
import Icon from 'components/Icon'
import ProgressBar from './components/ProgressBar'
import Loader from 'components/Loader'

const GalleryPage = ({ userID, myProfile }) => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()

    const { setAlert } = useContext(AlertContext)

    const [index, setIndex] = useState(-1)
    const [galleryImages, setGalleryImages] = useState([])
    const [selectActive, setSelectActive] = useState(false)
    const [uploadActive, setUploadActive] = useState(false)
    const hasSelectedImages = useMemo(() => {
        return galleryImages.some((image) => image.isSelected === true)
    }, [galleryImages])
    const [rejectedFiles, setRejectedFiles] = useState([])

    const uploadedPhotos = galleryImages.length > 0

    const showSelectButtons =
        (selectActive || hasSelectedImages) && uploadedPhotos
    const finite = galleryImages.length > 0 && galleryImages.length < 5

    const {
        data: images,
        fetchData: getImages,
        isLoading: isImagesLoading,
    } = useFetchData(
        ENTITIES.GALLERY_IMAGES,
        { 'user.id': userID },
        true,
        false
    )

    const {
        uppyAddFiles,
        uppyUpload,
        progressObject,
        setProgressObject,
        hidePercentageBar,
        setHidePercentageBar,
    } = useUppy(() => {
        getImages()
    })

    useEffect(() => {
        setGalleryImages(
            images.map(({ id, path, width, height, name, originalName }) => ({
                id,
                src: BASE_FILE_URL + path,
                height,
                width,
                name,
                originalName,
            }))
        )
        setUploadActive(false)
    }, [images])

    useEffect(() => {
        if (images.length === 0 || images.length === undefined) {
            setHidePercentageBar(true)
            setProgressObject((progress) => {
                return {
                    ...progress,
                    files: {},
                }
            })
        }
        const timer = setTimeout(() => {
            setHidePercentageBar(true)
            setProgressObject((progress) => {
                return {
                    ...progress,
                    files: {},
                }
            })
        }, 30000)

        return () => clearTimeout(timer)
    }, [images])

    const onDrop = useCallback(async (acceptedFiles) => {
        if (acceptedFiles.length > MAX_NUMBER_OF_PICTURES_FOR_UPLOAD) {
            setAlert(
                t('alerts.messages.maxNumberOfPhotosForUpload'),
                ALERT_TYPES.ERROR
            )
            return
        }

        setRejectedFiles([])

        await uppyAddFiles(acceptedFiles)
        uppyUpload()
    }, [])

    const handleDropRejected = (rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            setAlert(t('alerts.messages.invalidFileType'), ALERT_TYPES.ERROR)
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        onDropRejected: handleDropRejected,
        accept: {
            'image/png': [],
            'image/jpeg': [],
            'image/jpg': [],
            'application/octet-stream': [],
            'image/webp': [],
            'image/heic': [],
            'image/heif': [],
        },
    })

    const handleDelete = async () => {
        const selectedImages = galleryImages.filter((image) => image.isSelected)
        const files = {}

        selectedImages.forEach((img) => {
            files[img.id] = {
                percentage: 0,
                name: img.originalName,
            }
        })

        for (let i = 0; i < selectedImages.length; i++) {
            if (selectedImages[i].isSelected) {
                try {
                    await deleteEntityService(
                        ENTITIES.GALLERY_IMAGES,
                        selectedImages[i].id,
                        {}
                    )

                    setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
                } catch (error) {
                    setAlert(error, ALERT_TYPES.ERROR, t)
                }

                /** Add delay to see percentage and info */
                setTimeout(() => {
                    setProgressObject({
                        files: {},
                        numberOfFiles: 0,
                        percentage: 0,
                        status: UPLOAD_STATUS.DELETING,
                    })
                }, 500)
            }
        }

        getImages()
    }

    const handleClick = (index, item) => {
        setIndex(index)
    }

    const handleSelect = (index, item, event) => {
        const newImages = galleryImages.map((image, i) =>
            i === index ? { ...image, isSelected: !image.isSelected } : image
        )

        setGalleryImages(newImages)
    }

    const changeSelectStatusForAll = (newValue) => {
        const newImages = galleryImages.map((image) => {
            return { ...image, isSelected: newValue }
        })

        setGalleryImages(newImages)
    }

    const selectAll = () => {
        changeSelectStatusForAll(true)
    }

    const deselectAll = () => {
        changeSelectStatusForAll(false)
    }

    if (isImagesLoading) {
        return <Loader />
    }

    if (!images) {
        return null
    }

    return (
        <div className="_wr">
            <div className="_w">
                <div className="m-boxes fullWidth">
                    <div className="m-gallery">
                        <div className="space-between -mb20">
                            <h3>
                                {t('menuItems.gallery')}{' '}
                                {uploadedPhotos
                                    ? '(' + galleryImages.length + ')'
                                    : ''}
                            </h3>

                            {uploadedPhotos && myProfile && (
                                <div className="space-between-buttons ">
                                    <Button
                                        btnClass={BUTTON_STATUS.SECONDARY}
                                        label={
                                            !showSelectButtons
                                                ? 'button.select'
                                                : 'button.cancel'
                                        }
                                        buttonSize={BUTTON_SIZE.MEDIUM}
                                        onClick={() => {
                                            setSelectActive(!selectActive)
                                            changeSelectStatusForAll(false)
                                        }}
                                    />
                                    <Button
                                        icon={ICONS.UPLOAD}
                                        iconColor={COLORS.WHITE}
                                        buttonSize={BUTTON_SIZE.MEDIUM}
                                        onClick={() => {
                                            setUploadActive(!uploadActive)
                                        }}
                                    />
                                </div>
                            )}
                        </div>

                        {showSelectButtons && (
                            <div className="space-between m-gallery__selectActive -mb20">
                                <div className="space-between-buttons">
                                    <Button
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        icon={ICONS.CHECKMARK_ICON_ROUNDED}
                                        iconColor={COLORS.LIGHT_BLUE}
                                        label={'button.selectAll'}
                                        buttonSize={BUTTON_SIZE.MEDIUM}
                                        onClick={selectAll}
                                    />
                                    <Button
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        icon={ICONS.CLOSE}
                                        iconColor={COLORS.LIGHT_BLUE}
                                        label={'button.deselectAll'}
                                        buttonSize={BUTTON_SIZE.MEDIUM}
                                        onClick={deselectAll}
                                    />
                                </div>
                                <Button
                                    disabled={!hasSelectedImages}
                                    btnClass={BUTTON_STATUS.TERTIARY}
                                    icon={ICONS.DELETE}
                                    iconColor={COLORS.RED}
                                    iconSize={ICON_SIZE.SIZE18}
                                    label={'button.deleteSelectedPhotos'}
                                    onClick={handleDelete}
                                />
                            </div>
                        )}
                        {!hidePercentageBar && (
                            <ProgressBar
                                progressObject={progressObject}
                                setHidePercentageBar={setHidePercentageBar}
                                setProgressObject={setProgressObject}
                                rejectedFiles={rejectedFiles}
                            />
                        )}

                        {(uploadActive || !uploadedPhotos) &&
                            (myProfile ? (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div
                                        className={`aligned-center justify-center column m-gallery__box ${
                                            uploadedPhotos
                                                ? '-photos'
                                                : '-noPhotos'
                                        }`}
                                    >
                                        <div
                                            className={`aligned-center column ${
                                                myProfile
                                                    ? 'm-gallery__box--content'
                                                    : ''
                                            }`}
                                        >
                                            {!uploadedPhotos && (
                                                <Fragment>
                                                    <Icon
                                                        name={
                                                            ICONS.GALLERY_PHOTO
                                                        }
                                                    />
                                                    <h3 className="-mt10">
                                                        {t('general.noPhotos')}
                                                    </h3>
                                                </Fragment>
                                            )}

                                            <div className="aligned-center column">
                                                {isDragActive ? (
                                                    <span className="-mt20 a-bodyTextRegular a-lightText -opacity-60">
                                                        {t('general.dropFile')}
                                                    </span>
                                                ) : (
                                                    <>
                                                        {myProfile && (
                                                            <span className="-mt20 a-bodyTextRegular a-lightText -opacity-60">
                                                                {t(
                                                                    isTablet
                                                                        ? 'gallery.addPhotosFromDevice'
                                                                        : 'gallery.dragNDrop'
                                                                )}
                                                            </span>
                                                        )}
                                                        {!isTablet &&
                                                            myProfile && (
                                                                <span className="-mt10 a-bodyTextRegular a-lightText -opacity-60 a-allCaps">
                                                                    {t(
                                                                        'gallery.or'
                                                                    )}
                                                                </span>
                                                            )}
                                                        {myProfile && (
                                                            <div className="justify-start -mt10">
                                                                <Button label="gallery.chooseFromDevice" />
                                                            </div>
                                                        )}
                                                        {myProfile && (
                                                            <span className="-mt20 a-captionsTextRegular a-lightText">
                                                                {t(
                                                                    'general.supportedFormats'
                                                                )}
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div
                                        className={`aligned-center justify-center column m-gallery__box ${
                                            uploadedPhotos
                                                ? '-photos'
                                                : '-noPhotos'
                                        }`}
                                    >
                                        <div
                                            className={`aligned-center column ${
                                                myProfile
                                                    ? 'm-gallery__box--content'
                                                    : ''
                                            }`}
                                        >
                                            {!uploadedPhotos && (
                                                <Fragment>
                                                    <Icon
                                                        name={
                                                            ICONS.GALLERY_PHOTO
                                                        }
                                                    />
                                                    <h3 className="-mt10">
                                                        {t('general.noPhotos')}
                                                    </h3>
                                                </Fragment>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}

                        <div
                            className={`-mt20 ${
                                selectActive ? '-allowSelection' : ''
                            }`}
                        >
                            <Gallery
                                images={galleryImages}
                                onClick={handleClick}
                                onSelect={handleSelect}
                            />
                        </div>

                        <Lightbox
                            slides={galleryImages}
                            open={index >= 0}
                            index={index}
                            close={() => setIndex(-1)}
                            controller={{
                                closeOnPullDown: true,
                                closeOnBackdropClick: true,
                            }}
                            carousel={{
                                finite: finite,
                            }}
                            plugins={[Captions, Thumbnails, Zoom]}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GalleryPage
