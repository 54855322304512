import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { ContractorContext } from '../../context/ContractorContext'

import { editEntityService } from 'services/entity.service'
import {
    MOMENT_FORMATS,
    getIsDateSameOrBeforeCurrentDate,
    getTodaysDate,
} from 'services/moment.service'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'
import REGEXP from 'constants/regex'
import ROUTES from 'constants/routes'

import FocusError from 'components/FocusError'
import {
    DateTimeField,
    MultiselectField,
    ProfilePictureUploadField,
    TextAreaField,
} from 'components/formFields'
import InputField from 'components/formFields/InputField'
import SelectField from 'components/formFields/SelectField'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import Separator from 'components/Separator'
import { MAX_CHARS_ABOUT } from 'utils/validationConstants'

const CompanyInfoModal = ({ open, setOpen }) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { fetchCurrentUser, fetchCompanyData } = useContext(
        CurrentUserContext
    )
    const { company, fetchContractor } = useContext(ContractorContext)
    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        name: company.name ?? '',
        hqCountry: company.hqCountry ?? null,
        hqAddress: company.hqAddress ?? '',
        size: company.size ?? null,
        founded: company.founded ?? '',
        tinVat: company.tinVat ?? '',
        email: company.email ?? '',
        countryPhoneCode: company.countryPhoneCode ?? null,
        phoneNumber: company.phoneNumber ?? '',
        website: company.website ?? '',
        contactPersonFirstName: company.user.firstName ?? '',
        contactPersonLastName: company.user.lastName ?? '',
        about: company.about ?? '',
        industries: company.industries ?? [],
        organizationTypes: company.organizationTypes ?? [],
        services: company.services ?? [],
        locationOfServices: company.locationOfServices ?? [],
        vessels: company.vessels ?? [],
        clients: company.clients ?? [],
        assetsAndEquipment: company.assetsAndEquipment ?? '',
        logo: company.logo ?? null,
    }

    const requiredMessage = t('form.error.required')
    const maximumCharacters = `${t(
        'form.error.maximumAllowed'
    )} ${MAX_CHARS_ABOUT} ${t('form.error.characters')}`

    const validation = Yup.object({
        name: Yup.string().trim().required(requiredMessage),
        hqCountry: Yup.object().required(requiredMessage),
        hqAddress: Yup.string().trim().required(requiredMessage),
        size: Yup.object().required(requiredMessage),
        founded: Yup.date()
            .test('founded', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)
                ) {
                    return true
                }
                return createError({
                    message: t('form.error.foundedDate'),
                    path: 'founded',
                })
            })
            .required(requiredMessage),
        tinVat: Yup.string().trim().required(requiredMessage),
        email: Yup.string()
            .email(t('form.error.invalidEmail'))
            .matches(REGEXP.EMAIL, t('form.error.invalidEmail'))
            .required(requiredMessage),
        countryPhoneCode: Yup.object().required(requiredMessage),
        phoneNumber: Yup.string()
            .matches(REGEXP.DIGITS, 'Must be only digits')
            .test(
                'phoneNumber',
                'Must be maximum 15 digits',
                (val) => val.length <= 15
            )
            .typeError(t('form.error.invalidPhone'))
            .required(requiredMessage),
        website: Yup.string()
            .matches(REGEXP.REGEXP_URL, t('form.error.websiteURL'))
            .required(requiredMessage),
        contactPersonFirstName: Yup.string()
            .max(125, t('form.error.maximumCharLimit'))
            .trim()
            .required(requiredMessage),
        contactPersonLastName: Yup.string()
            .max(125, t('form.error.maximumCharLimit'))
            .trim()
            .required(requiredMessage),
        about: Yup.string().max(MAX_CHARS_ABOUT, maximumCharacters),
        industries: Yup.array()
            .min(1, requiredMessage)
            .required(requiredMessage),
        organizationTypes: Yup.array()
            .min(1, requiredMessage)
            .required(requiredMessage),
        services: Yup.array().min(1, requiredMessage).required(requiredMessage),
        locationOfServices: Yup.array()
            .min(1, requiredMessage)
            .required(requiredMessage),
        vessels: Yup.array(),
        clients: Yup.array(),
        assetsAndEquipment: Yup.string().max(500, maximumCharacters),
    })

    const handleSubmit = async (
        { contactPersonFirstName, contactPersonLastName, ...formData },
        { setSubmitting }
    ) => {
        try {
            setSubmitting(true)
            const response = await editEntityService(
                ENTITIES.COMPANY,
                company.id,
                formData,
                true,
                ['locationOfServices']
            )
            await editEntityService(
                ENTITIES.USER,
                company.user.id,
                {
                    firstName: contactPersonFirstName,
                    lastName: contactPersonLastName,
                },
                true
            )
            fetchCurrentUser()
            fetchContractor({ profileHash: response.data.profileHash })
            fetchCompanyData()
            navigate(
                `${ROUTES.DIVING_CONTRACTOR_PROFILE}/${response.data.profileHash}`
            )
            setOpen(false)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, isSubmitting }) => (
                <Form className="_wr personalInfoModal">
                    <FocusError />
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        customClass="personalInformation"
                        title="general.editCompanyInformation"
                        isSubmitting={isSubmitting}
                        buttons={{
                            nextBtn: {
                                label: 'button.saveCompanyInformation',
                            },
                        }}
                    >
                        <div className="-contentElements">
                            <div className="_w">
                                <ProfilePictureUploadField
                                    name="logo"
                                    label="form.label.logoPath"
                                    multipleUpload={false}
                                    profilePage={true}
                                />
                                <div className="_12 _m9 -mainInfo">
                                    <div className="_w">
                                        <div className="_12 _m6">
                                            <InputField name="name" required />
                                        </div>

                                        <div className="_12 _m6">
                                            <SelectField
                                                name="size"
                                                label="form.label.companySize"
                                                placeholder="form.placeholder.companySize"
                                                entityType={
                                                    ENTITIES.COMPANY_SIZE
                                                }
                                                searchable
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="_w">
                                        <div className="_12 _m6">
                                            <SelectField
                                                name="hqCountry"
                                                label="form.label.hqFullAddress"
                                                placeholder="form.placeholder.country"
                                                entityType={ENTITIES.COUNTRY}
                                                searchable
                                                required
                                            />
                                        </div>
                                        <div className="_12 _m6 fakeLabel">
                                            <InputField
                                                name="hqAddress"
                                                label="general.fakeLabel"
                                                required
                                            />
                                        </div>
                                        <div className="_12 _m6">
                                            <DateTimeField
                                                name="founded"
                                                maxDate={getTodaysDate()}
                                                required
                                            />
                                        </div>
                                        <div className="_12 _m6">
                                            <InputField
                                                name="tinVat"
                                                tooltip="general.tinVatTooltip"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12 _l6">
                                    <InputField
                                        name="email"
                                        label="form.label.email"
                                        placeholder="form.placeholder.email"
                                        required
                                    />
                                </div>
                                <div className="_12 _l6">
                                    <div className="-phoneNumber fakeLabel -notFirst">
                                        <SelectField
                                            name="countryPhoneCode"
                                            label="form.label.phone"
                                            entityType={ENTITIES.COUNTRY}
                                            displayAttribute="callingCode"
                                            searchAttribute="callingCode"
                                            iconAttribute="flag"
                                            showPlaceholder={false}
                                            params={{ sort: 'callingCode' }}
                                            tooltip="general.corporatePhoneNumberTooltip"
                                            searchable
                                            required
                                        />
                                        <InputField
                                            name="phoneNumber"
                                            label="general.fakeLabel"
                                            placeholder="form.placeholder.phone"
                                            icon={ICONS.PHONE}
                                            iconColor={COLORS.DARK_BLUE_60}
                                            size={ICON_SIZE.SIZE20}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="_w ">
                                <Separator />
                                <div className="_12 _m6">
                                    <InputField name="website" required />
                                </div>
                            </div>
                            <div className="_w ">
                                <div className="_12 _m6">
                                    <InputField
                                        name="contactPersonFirstName"
                                        required
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="contactPersonLastName"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="_w ">
                                <Separator />
                                <div className="_12">
                                    <TextAreaField name="about" largeTextarea />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="industries"
                                        label="form.label.industriesYourOrganisationServes"
                                        placeholder="form.placeholder.selectIndustriesYourOrganisationServes"
                                        entityType={ENTITIES.INDUSTRY}
                                        searchable
                                        createNew={true}
                                        required
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="services"
                                        label="form.label.familyOfProvidedServices"
                                        placeholder="form.placeholder.familyOfProvidedServices"
                                        entityType={ENTITIES.SERVICE}
                                        searchable
                                        required
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="locationOfServices"
                                        label="form.label.locationsOfServices"
                                        placeholder="form.placeholder.locationsOfServices"
                                        entityType={
                                            ENTITIES.LOCATIONS_OF_SERVICES
                                        }
                                        searchable
                                        required
                                        idKey="entityId"
                                    />
                                </div>

                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="organizationTypes"
                                        label="form.label.categories"
                                        placeholder="form.placeholder.categories"
                                        entityType={ENTITIES.ORGANIZATION_TYPES}
                                        searchable
                                        required
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="vessels"
                                        label="form.label.vessels"
                                        entityType={ENTITIES.VESSELS}
                                        searchable
                                        createNew
                                        dropup
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="clients"
                                        label="form.label.clients"
                                        entityType={ENTITIES.CLIENTS}
                                        searchable
                                        createNew
                                        dropup
                                    />
                                </div>
                            </div>
                            <div className="_w ">
                                <Separator />
                                <div className="_12">
                                    <TextAreaField
                                        name="assetsAndEquipment"
                                        largeTextarea
                                    />
                                </div>
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

CompanyInfoModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default CompanyInfoModal
