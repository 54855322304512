import { Form, Formik } from 'formik'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import ENTITIES from 'constants/entities'

import FocusError from 'components/FocusError'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import SelectMultiselectGroupField from 'components/formFields/SelectMultiselectGroupField'

const SkillRecordForm = ({
    handleSubmit,
    initialData,
    setOpen,
    open,
    title,
}) => {
    const t = useTranslate()

    const initialValues = {
        skillRecords: initialData || [],
    }

    const validation = Yup.object({
        skillRecords: Yup.array(),
    })

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title={title}
                        smallModalWithDropdowns
                        isSubmitting={isSubmitting}
                        buttons={{
                            nextBtn: {
                                label: 'button.saveSkills',
                            },
                        }}
                    >
                        <div className="_wr">
                            <div className="-form _w">
                                <div className="a-oneLineSelectGroup _12">
                                    <SelectMultiselectGroupField
                                        name="skillRecords"
                                        title="general.addAllSkills"
                                        fieldsName={['skillCategory', 'skill']}
                                        fieldsPlaceholder={[
                                            'form.placeholder.skillCategory',
                                            'form.placeholder.skill',
                                        ]}
                                        fieldsEntityType={[
                                            ENTITIES.SKILL_CATEGORY,
                                            ENTITIES.SKILL,
                                        ]}
                                        fieldsShowLabel={[false, false]}
                                        fieldsRequired={[true, true]}
                                        disableAddOnlyForBoth={true}
                                        buttonLabel="button.add"
                                        secondFieldRelation="skillCategories.id"
                                        fieldsSearchable={[true, true]}
                                        listingTitle={t(
                                            'general.selectedSkills'
                                        )}
                                        disableOneField={[false, true]}
                                    />
                                </div>
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default SkillRecordForm
